import { useMutation } from "@apollo/client";

import React, { useState } from "react";
import { UPDATE_SELLER_STATUS } from "../../../../queries/sellers/queries";
import FilterHOC from "../../../Base/Filters/FilterHOC";
import statusLabel from "../../../../utils/statusLabel";
import StatusOption from "../../../StatusOption";

const Status = ({ statusOnChanged, id, status }) => {
  const [active, setActive] = useState(false);

  const [updateStatus, { loading }] = useMutation(UPDATE_SELLER_STATUS, {
    onCompleted: ({ updateSellerStatus: { success, sellerApplication } }) => {
      if (success) {
        statusOnChanged(sellerApplication.status);
        setActive(false);
      }
    },
    onError: (error) => {
      console.log(JSON.stringify(error));
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  const onChangeStatus = (status, e) => {
    e.stopPropagation();
    updateStatus({
      variables: {
        input: {
          sellerApplicationId: `${id}`,
          status,
        },
      },
    });
  };

  return (
    <FilterHOC status={active} setStatus={setActive} disabled={loading}>
      <span className="label">
        Status: {statusLabel(status) || "Not created"}
      </span>
      <label
        className="label-btn"
        htmlFor="status"
        onClick={(e) => {
          e.stopPropagation();
          setActive(!active);
        }}
      ></label>
      <div className="popup-menu">
        {[
          "pending",
          "approved_for_kyc",
          "kyc_accepted",
          "approved",
          "rejected_for_kyc",
          "kyc_declined",
          "declined",
        ].map((status) => (
          <StatusOption
            key={status}
            value={status}
            onChangeStatus={onChangeStatus}
          />
        ))}
      </div>
    </FilterHOC>
  );
};

export default React.memo(Status);

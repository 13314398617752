import { combineReducers } from "redux";

import authReducer from "./auth/reducer";
import modalsReducer from "./modals/reducer";
import businessDetailsReducer from "./business/reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  modals: modalsReducer,
  businessDetails: businessDetailsReducer,
});

export default rootReducer;

import React from "react";

const ConfirmDialog = ({ callbacks, text }) => {
  return (
    <>
      <img
        src="/images/icons/alert_question.svg"
        className="alert-block__img"
      />

      <h2 className="alert-block__title">Are you sure?</h2>

      <span className="alert-block__txt">{text}</span>

      <div className="alert-block__btns">
        <input
          type="button"
          value="Cancel"
          className="input input--btn input--btn--light"
          onClick={callbacks.finally}
        />
        <input
          type="button"
          value="Confirm"
          className="input input--btn"
          onClick={callbacks.success}
        />
      </div>
    </>
  );
};

export default ConfirmDialog;

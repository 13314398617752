import { useMutation, useQuery } from "@apollo/client";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GET_BUSINESS_STRUCTURE,
  CREATE_SELLER_APPLICATION,
  UPDATE_SELLER_APPLICATION,
} from "../../../../queries/business-structure/queries";
import { GET_SELLER_APPLICATION } from "../../../../queries/sellers/queries";
import types from "../../../../redux/types";
import BreadCrumbs from "../../../Base/BreadCrumbs";
import Preloader from "../../../Base/Preloader";
import Title from "../../../Base/Title";
import BankDetails from "./BankDetails";
import BusinessDetails from "./BusinessDetails";
import PersonalInformation from "./PersonalInformation";

const BusinessStructureContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { validated, ...localValues } = useSelector(
    (state) => state.businessDetails
  );

  const [sellerApplication, setSellerApplication] = useState({});

  // CONSTRUCTOR

  const [fields, setFields] = useState({});

  const { loading: l1 } = useQuery(GET_BUSINESS_STRUCTURE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setFields(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // ONSUBMIT ACIONS

  const { loading: l0 } = useQuery(GET_SELLER_APPLICATION, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onCompleted: ({ currentSellerApplication }) => {
      setSellerApplication(currentSellerApplication);

      // if (currentSellerApplication?.typeOfBusiness) {
      //   dispatch({
      //     type: types.UPDATE_BUSINESS_DETAILS,
      //     payload: {
      //       typeOfBusiness: currentSellerApplication?.typeOfBusiness,
      //     },
      //   });
      // }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [updateSellerApplication, { loading: l2 }] = useMutation(
    UPDATE_SELLER_APPLICATION,
    {
      onCompleted: ({ updateSellerApplication: { success, errors } }) => {
        if (success) {
          navigate("/settings/company-profile");
        } else {
          console.log(errors?.[0]?.message || "Unknow error");
        }
      },
      onError: (error) => {
        console.log(JSON.stringify(error));
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    }
  );

  const [createSellerApplication, { loading: l3 }] = useMutation(
    CREATE_SELLER_APPLICATION,
    {
      onCompleted: ({ createSellerApplication: { success, errors } }) => {
        if (success) {
          navigate("/settings/company-profile");
        } else {
          console.log(errors?.[0]?.message || "Unknow error");
        }
      },
      onError: (error) => {
        console.log(JSON.stringify(error));
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    }
  );

  const onSubmit = () => {
    if (submitEnable) {
      let formState = {
        country: localValues?.country,
        region: localValues?.region,
        address: localValues?.address,
        website: localValues?.website,
        platformId: +localValues?.platformId,
        revenueId: +localValues?.revenueId,
        typeOfBusiness: localValues?.typeOfBusiness,

        bankId: +localValues?.bankId,
        routingNumber: localValues?.routingNumber,
        accountNumber: localValues?.accountNumber,
        accountNumberConfirmation: localValues?.accountNumberConfirmation,
      };

      if (localValues.typeOfBusiness === "SOLE_PROPRIETORSHIP") {
        formState = {
          ...formState,
          identificationNumber: localValues?.identificationNumber,
        };
      } else {
        formState = {
          ...formState,
          legalBusinessName: localValues?.legalBusinessName,
          companyRegistrationNumber: localValues?.companyRegistrationNumber,
          vatNumber: localValues?.vatNumber,
          doingBusinessAs: localValues?.doingBusinessAs,
        };
      }

      sellerApplication
        ? updateSellerApplication({
            variables: {
              input: {
                ...formState,
                id: sellerApplication?.id,
              },
            },
          })
        : createSellerApplication({
            variables: {
              input: {
                ...formState,
              },
            },
          });
    }
  };

  const onChange = (data) => {
    dispatch({
      type: types.UPDATE_BUSINESS_DETAILS,
      payload: {
        ...data,
      },
    });
  };

  // HOOKS INIT

  const submitEnable = useMemo(() => {
    return validated
      ? Object.values(validated).reduce((acc, val) => acc && val)
      : false && !(l0 || l1 || l2 || l3);
  }, [validated, l0, l1, l2, l3]);

  return (
    <BusinessStructure
      sellerApplication={sellerApplication}
      localValues={localValues}
      fields={fields}
      onChange={onChange}
      onSubmit={onSubmit}
      submitEnable={submitEnable}
      loading={l0}
    />
  );
};

const BusinessStructure = ({
  sellerApplication,
  localValues,
  fields,
  onChange,
  onSubmit,
  submitEnable,
  loading,
}) => {
  return loading ? (
    <Preloader />
  ) : (
    <>
      <BreadCrumbs
        links={[
          {
            to: "/settings/company-profile",
            name: "Summary",
          },
          {
            to: "/settings/company-profile/business-details",
            name: sellerApplication ? "Edition" : "Creation",
          },
        ]}
      />

      <Title title="Company Profile" />

      <div
        className={classNames("main-content", {
          "main-content--sole-proprietorship":
            localValues?.typeOfBusiness === "SOLE_PROPRIETORSHIP",
        })}
      >
        <PersonalInformation
          sellerApplication={
            sellerApplication?.status ? sellerApplication : localValues
          }
          fields={fields}
          onChange={onChange}
        />

        {localValues?.typeOfBusiness !== "SOLE_PROPRIETORSHIP" ? (
          <BusinessDetails
            sellerApplication={
              sellerApplication?.status ? sellerApplication : localValues
            }
            onChange={onChange}
          />
        ) : null}

        <BankDetails
          sellerApplication={
            sellerApplication?.status ? sellerApplication : localValues
          }
          fields={fields}
          onChange={onChange}
          onSubmit={onSubmit}
          submitEnable={submitEnable}
        />
      </div>
    </>
  );
};

export default React.memo(BusinessStructureContainer);

// import { store } from "../redux/store";

export const getChangedValues = (values, initialValues) => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    const hasChanged = initialValues[key] !== value;

    if (hasChanged) {
      acc[key] = value;
    }

    return acc;
  }, {});
};

export const onDynamicTitle = (pathname) => {
  switch (pathname) {
    case "/":
      return (document.title = `Welcome to AmnaPay - AmnaPay`);

    case "/auth/signin":
      return (document.title = `SignIn - AmnaPay`);

    case "/auth/forgot-password":
      return (document.title = `Recover password - AmnaPay`);

    case "/auth/forgot-password/temporary":
      return (document.title = `Enter your temporary password - AmnaPay`);

    case "/auth/forgot-password/new-password":
      return (document.title = `Create new password - AmnaPay`);

    case "/profile":
      return (document.title = `Dashboard - AmnaPay`);

    case "/profile/transactions":
      return (document.title = `Transactions - AmnaPay`);

    case "/profile/payments":
      return (document.title = `Payments - AmnaPay`);

    case "/profile/cards":
      return (document.title = `Cards - AmnaPay`);

    case "/profile/account":
      return (document.title = `Account - AmnaPay`);

    case "/profile/products":
      return (document.title = `Products - AmnaPay`);

    case "/profile/sellers_applications":
      return (document.title = `Sellers' applications - AmnaPay`);

    case "/settings/company-profile":
      return (document.title = `Summary - AmnaPay`);

    case "/settings/personal-profile":
      return (document.title = `Personal profile - AmnaPay`);

    case "/settings/integrations":
      return (document.title = `Integrations - AmnaPay`);

    case "/settings/api":
      return (document.title = `API - AmnaPay`);

    case "/settings/team":
      return (document.title = `Team - AmnaPay`);

    case "/settings/notification":
      return (document.title = `Notification - AmnaPay`);

    case "/settings/security":
      return (document.title = `Security - AmnaPay`);

    default:
      return (document.title = `AmnaPay`);
  }
};

export const capitalizeFirstLetter = (
  [first, ...rest],
  locale = navigator.language
) => first.toLocaleUpperCase(locale) + rest.join("").toLowerCase();

export const cutCardNumber = (card) => {
  return `•••• ${card
    .toString()
    .slice(-4)
    .replace(/(.{4})/g, "$1 ")}`;
};

export const cutAccountNumber = (card) => {
  return `•••• •••• •••• ${card.toString().slice(-4)}`;
};

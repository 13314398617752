/* eslint-disable no-undef */
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";
import rootReducer from "./rootReducer";
import { rootSaga } from "./rootSagaWatcher";

export const sagaMiddleware = createSagaMiddleware();

const enhancer =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION__
      ? compose(
          applyMiddleware(sagaMiddleware),
          window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
        )
      : compose(applyMiddleware(sagaMiddleware))
    : applyMiddleware(sagaMiddleware);

export const store = createStore(rootReducer, enhancer);
sagaMiddleware.run(rootSaga);
export const persistor = persistStore(store);

/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import CustomSelect from "../CustomSelect";
import style from "../style.module.scss";

const Step1 = ({ prevStep, nextStep }) => {
  const [value, setValue] = useState("");
  const [transferFrom, setTransferFrom] = useState("");
  const [transferTo, setTransferTo] = useState("");

  return (
    <>
      <div className={style.card}>
        <div className={style.row}>
          <div className={style.field}>
            <div className={style.preffix}>$</div>

            <input
              type="text"
              onChange={(e) => setValue(e.currentTarget.value)}
              value={value}
              placeholder="0.00"
            />
          </div>
        </div>

        <div className={style.row}>
          <div className={style.field_select}>
            <div className={style.title}>Transfer from</div>

            <CustomSelect value={transferFrom} setValue={setTransferFrom} />
          </div>

          <div className={style.calendar}>
            <img src="/images/icons/clndr.svg" />
            External transfers take up to 3 business days.
          </div>
        </div>

        <div className={style.row}>
          <div className={style.field_select}>
            <div className={style.title}>Transfer to</div>

            <CustomSelect value={transferTo} setValue={setTransferTo} />
          </div>
        </div>

        <div className={style.row}>
          <div className={style.footer}>
            <button onClick={prevStep} className={style.back}>
              <img src="/images/icons/toprev.svg" />
              Go back
            </button>
            <button onClick={nextStep} disabled={!value}>
              Next <img src="/images/icons/tonext.svg" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step1;

/* eslint-disable no-unused-vars */
import "react-phone-number-input/style.css";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import AuthCode from "react-auth-code-input";
import PhoneInput from "react-phone-number-input";
import { useFormikContext } from "formik";

const FormControl = ({
  form,
  field,
  title,
  sendBtnCallback,
  sendBtnStatus,
  label = true,
  ...props
}) => {
  const errors = form.errors[field.name];
  const touched = form.touched[field.name];

  const [input, ...children] = Array.isArray(props.children)
    ? props.children
    : [props.children];

  return (
    <div
      className={classNames("form__el form__el--filled", {
        "txt--error-block": errors && touched,
      })}
    >
      {input}

      {label && title && <label className="form__el-label">{title}</label>}

      {sendBtnCallback && (
        <div className="form__el-btns-input">
          <input
            onClick={sendBtnCallback}
            type="button"
            value="Send"
            className="input input--btn small"
            disabled={
              (errors && touched) || !form.values[field.name] || sendBtnStatus
            }
          />
        </div>
      )}

      {children && children}

      {errors && touched && (
        <div className="txt--small txt--error">{errors}</div>
      )}
    </div>
  );
};

export const Select = (props) => {
  return (
    <FormControl {...props}>
      <select
        className="input input--txt input--select"
        {...props.field}
        value={props.field.value || "default"}
      >
        <option disabled value="default">{`Select ${props.field.name}`}</option>

        {(props.options || []).map((e, i) => (
          <option value={e?.value || e?.name} key={i}>
            {e?.name || e?.value}
          </option>
        ))}
      </select>
    </FormControl>
  );
};

export const Input = (props) => {
  return (
    <FormControl {...props}>
      <input className="input input--txt form__el-input" {...props.field} />
    </FormControl>
  );
};

export const Textarea = (props) => {
  return (
    <FormControl {...props}>
      <textarea
        onInput={(e) => {
          e.currentTarget.style.height = "";
          e.currentTarget.style.height = e.currentTarget.scrollHeight + "px";
        }}
        className={classNames(
          "input input--txt form__el-textarea",
          props?.className
        )}
        maxLength={props?.maxlength}
        {...props.field}
      />
      {props?.maxlength && (
        <div className="form__el-footer">
          <span>
            {props.field.value.length} / {props.maxlength}
          </span>
        </div>
      )}
    </FormControl>
  );
};

export const InputPhoneNumber = (props) => {
  const [value, setValue] = useState(props.field.value);
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (value?.[0] !== "+") {
      setValue(`+${value}`);
    } else {
      setFieldValue(props.field.name, value || "+");
    }
  }, [value]);

  return (
    <FormControl {...props}>
      <PhoneInput
        name={props.field.name}
        className="input input--txt form__el-input"
        onChange={setValue}
        value={value}
      />
    </FormControl>
  );
};

export const InputCheckBox = ({ ...props }) => {
  return (
    <div className="form__el">
      <label className="label">
        <input
          className="input--checkbox"
          {...props.field}
          type={props.type}
          inputMode={props.inputMode || null}
        />
        {props.children}
      </label>
    </div>
  );
};

export const Radio = (props) => {
  return (
    <FormControl {...props}>
      <div className="form__el-radio">
        {props.controls.map((c, index) => {
          return (
            <label
              className={classNames("form__el-radio-label", {
                "form__el-radio-label--checked": props.field.value === c.id,
              })}
              key={index}
            >
              <input
                {...props.field}
                className="input--radio"
                checked={props.field.value === c.id}
                type="radio"
                value={c.id}
              />

              <p className="p1">{c.name}</p>
            </label>
          );
        })}
      </div>
    </FormControl>
  );
};

export const DigitCode = ({
  form,
  field,
  onSubmit,
  disabled,
  status,
  autoSubmit = false,
}) => {
  const errors = form.errors[field.name];
  const touched = form.touched[field.name];

  useEffect(() => {
    if (field.value.length === 6) {
      document.activeElement.blur();

      if (autoSubmit) {
        onSubmit();
      }
    }
  }, [field.value]);

  return (
    <div
      className={classNames("form__el form__el--filled", {
        "txt--error-block": errors && touched,
      })}
    >
      <label className="form__el-label" htmlFor="firstName">
        Enter the code you received
      </label>

      <AuthCode
        inputType="number"
        characters={6}
        onChange={(value) => form.setFieldValue(field.name, value)}
        containerClassName={classNames("form__el-verification", {
          disabled: disabled || status,
        })}
        inputClassName="input"
      />

      {status && (
        <img
          src="/images/icons/business_structure/verification_valiable.svg"
          className="form__el-verification-valiable"
        />
      )}

      {errors && (
        <img
          src="/images/icons/business_structure/subtract.svg"
          className="form__el-verification-valiable"
        />
      )}

      {errors && <div className="txt--small txt--error">{errors}</div>}
    </div>
  );
};

export const Photo = (props) => {
  const ref = useRef();

  useEffect(() => {
    if (props.field.value) {
      const reader = new FileReader();

      reader.onload = (e) => {
        ref.current.style.backgroundImage =
          'url("' + e.currentTarget.result + '")';
      };

      reader.readAsDataURL(props.field.value);
    }
  }, [props.field.value]);

  return (
    <FormControl label={false} {...props}>
      <div className="field__photo-container">
        <div
          ref={ref}
          className="field__photo"
          style={{ backgroundImage: "url(/images/icons/photo.svg)" }}
        />

        <label className="form__photo-input input--btn">
          <input
            ref={props.childref}
            type={"file"}
            {...props}
            onChange={(event) => {
              props.form.setFieldValue(
                props.field.name,
                event.currentTarget.files[0]
              );
            }}
          />
          Upload new image
        </label>
      </div>
    </FormControl>
  );
};

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

export default function VirtualCardList({ virtualCards, loading }) {
  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <>
      <div
        style={{
          display: `flex`,
          justifyContent: `space-between`,
          alignItems: `center`,
          borderBottom: `1px solid #d6d6d6`,
          padding: `0rem 1.5rem 1rem`,
          width: `20rem`,
        }}
      >
        <h1>All Cards</h1>
        <NewButtonLink to="new">+</NewButtonLink>
      </div>
      <ul style={{ marginTop: `1rem` }}>
        {virtualCards.map((card) => (
          <VirtualCard key={card.id} virtualCard={card} />
        ))}
      </ul>
    </>
  );
}

function VirtualCard({ virtualCard }) {
  const { id, cardName, cardNumber } = virtualCard || {};
  const maskedNumber = cardNumber.slice(12, 16);

  return (
    <li style={{ listStyle: `none`, width: `20rem` }}>
      <VirtualCardLink to={id}>
        <div className="name">{cardName || "Unnamed Card"}</div>
        <div className="masked">{maskedNumber}</div>
      </VirtualCardLink>
    </li>
  );
}

const VirtualCardLink = styled(Link)`
  display: block;

  padding: 1rem 1.5rem;
  text-decoration: none;
  background: linear-gradient(#fafafa, #eee);

  .name {
    font-weight: bold;
    color: #444;
  }

  .masked {
    text-align: right;
    color: #666;
  }
`;

const NewButtonLink = styled(Link)`
  font-size: 2rem;
  text-decoration: none;
  color: var(--blue);
`;

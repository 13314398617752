import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import SettingsBody from "./SettingsBody";

const SettingsContainer = ({ collapse }) => {
  const [status, setStatus] = useState(false);

  return <Settings status={status} setStatus={setStatus} collapse={collapse} />;
};

const Settings = ({ status, setStatus, collapse }) => {
  return status ? (
    <SettingsBody setStatus={setStatus} />
  ) : (
    <NavLink
      to="#"
      className="link main-menu__link main-menu__footer-settings"
      onClick={() => {
        setStatus(true);
      }}
    >
      <img
        src="/images/icons/profile/settings.svg"
        className="main-menu__link-block"
      />
      <img
        src="/images/icons/profile/settings_active.svg"
        className="main-menu__link-block--active"
      />
      {!collapse && "Settings"}
    </NavLink>
  );
};

export default React.memo(SettingsContainer);

import React from "react";

const Dropdown = ({ name, onClick }) => {
  return (
    <div
      className={"main-header__filters-item popup"}
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <span className="label">{name}</span>

      <label className="label-btn" htmlFor="status"></label>
    </div>
  );
};

export default Dropdown;

import React from "react";
import { NavLink } from "react-router-dom";

const RollBack = () => {
  return (
    <NavLink
      to={"/"}
      className="link link--nav"
      onClick={(e) => {
        e.preventDefault();
        window.history.back();
      }}
    >
      <div className="link--nav-img"></div>
      Back
    </NavLink>
  );
};

export default React.memo(RollBack);

import React from "react";
import { NavLink } from "react-router-dom";
import style from "./style.module.scss";

const Link = ({ children, ...props }) => {
  return (
    <NavLink className={style.link} {...props}>
      {children}
    </NavLink>
  );
};

export { Link };

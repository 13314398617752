import React, { useEffect, useRef, useState } from "react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import types from "../../../../redux/types";
import style from "./style.module.scss";

const ElipsisMenu = () => {
  const [active, setActive] = useState(false);

  const dispatch = useDispatch();
  const ref = useRef(null);

  useEffect(() => {
    const clickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setActive(false);
      }
    };

    document.addEventListener("mousedown", clickOutside);

    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [ref]);

  const modalClose = () =>
    dispatch({
      type: types.HIDE_MODAL,
    });

  const onShowModalEdit = useCallback(() => {
    dispatch({
      type: types.SHOW_MODAL,
      payload: {
        name: "ChangeLinkedAccount",
        callbacks: {
          success: () => {
            modalClose();
          },
          error: () => {},
          finally: () => {
            modalClose();
          },
        },
      },
    });
  }, []);

  const onUnlinkAccount = useCallback(() => {
    dispatch({
      type: types.SHOW_MODAL,
      payload: {
        name: "ConfirmDialog",
        text: "Are you sure you want to unlink this account?",
        callbacks: {
          finally: () => modalClose(),
          success: () => modalClose(),
        },
      },
    });
  }, []);

  return (
    <div className={style.menu}>
      <button className={style.btn} onClick={() => setActive(!active)} />

      {active && (
        <div className={style.content} ref={ref}>
          <div
            className={style.content_btn}
            onClick={() => {
              setActive(false);
              onShowModalEdit();
            }}
          >
            <img src="/images/icons/edit.svg" />
            Edit Account Name
          </div>
          <div
            className={style.content_btn}
            onClick={() => {
              setActive(false);
              onUnlinkAccount();
            }}
          >
            <img src="/images/icons/link.svg" />
            Unlink Account
          </div>
        </div>
      )}
    </div>
  );
};

export default ElipsisMenu;

import React, { useState } from "react";

export function MaskedCVV({ cvv }) {
  const [masked, setMasked] = useState(true);

  return (
    <>
      {masked ? "***" : cvv}{" "}
      <button onClick={() => setMasked(!masked)} style={{ border: `none` }}>
        {masked ? "Reveal" : "Hide"}
      </button>
    </>
  );
}

import React from "react";
import { useParams } from "react-router-dom";
import { Field } from "formik";
import { Input } from "../../../utils/formcontrol";

export default function VirtualCardFields({ title }) {
  const { virtualCardId } = useParams();

  return (
    <>
      <h2 className="form__el-headline">{title}</h2>
      <input type="hidden" name="businessId" />
      <Field
        type="text"
        title="Card Name *"
        name="cardName"
        component={Input}
      />

      {!virtualCardId && (
        <>
          <label htmlFor="dollarLimit">Card Limit</label>{" "}
          <Field as="select" name="dollarLimit" title="Limit">
            <option value="100">$100</option>
            <option value="500">$500</option>
            <option value="1000">$1000</option>
          </Field>
          <div style={{ marginBottom: `45px` }}></div>
        </>
      )}

      <input type="submit" value="Save" className="input input--btn" />
    </>
  );
}

import { useQuery } from "@apollo/client";
import classNames from "classnames";
import React, { useEffect, useState } from "react";

import { NavLink, useParams } from "react-router-dom";
import { animateScroll } from "react-scroll/modules";
import {
  GET_SELLER_APPLICATION,
  GET_SELLER_APPLICATION_BY_ID,
} from "../../../../queries/sellers/queries";
import { capitalizeFirstLetter } from "../../../../services/helpers";
import BreadCrumbs from "../../../Base/BreadCrumbs";
import Preloader from "../../../Base/Preloader";
import Title from "../../../Base/Title";
import Comments from "./Comments";
import History from "./History";
import Status from "./Status";
import ApplicationStatusBadge from "../../../ApplicationStatusBadge";

const SummaryContainer = ({ underwriter }) => {
  const { id } = useParams();
  const [sellerApplication, setSellerApplication] = useState({});

  const { loading } = useQuery(
    id ? GET_SELLER_APPLICATION_BY_ID : GET_SELLER_APPLICATION,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      onCompleted: ({ sellerApplication, currentSellerApplication }) => {
        setSellerApplication(sellerApplication || currentSellerApplication);
      },
      onError: (error) => {
        console.log(error);
      },
      variables: id ? { id: +id } : null,
    }
  );

  return (
    <Summary
      statusOnChanged={(status) =>
        setSellerApplication({
          ...sellerApplication,
          status: status,
        })
      }
      underwriter={underwriter}
      sellerApplication={sellerApplication}
      loading={loading}
      name={`${sellerApplication?.user?.firstName || "No name"} ${
        sellerApplication?.user?.lastName || ""
      }`}
    />
  );
};

const Summary = ({
  statusOnChanged,
  underwriter,
  sellerApplication,
  name,
  loading,
}) => {
  const [active, setActive] = useState(true);
  const [footer, setFooter] = useState("history");

  useEffect(() => {
    if (sellerApplication?.histories?.length) {
      setFooter("history");
    } else {
      setFooter("comments");
    }
  }, [sellerApplication]);

  return loading ? (
    <Preloader />
  ) : (
    <>
      <BreadCrumbs
        links={[
          {
            to: "/settings/company-profile",
            name: "Summary",
          },
        ]}
      />

      <Title title="Summary ">
        {underwriter && (
          <Status
            statusOnChanged={statusOnChanged}
            id={+sellerApplication.id}
            status={sellerApplication.status}
          />
        )}
      </Title>

      <div className="main-content">
        <div className="main-content__title main-content__title--flex">
          <div className="main-content__title-item">
            <p className="txt">{name || "-"}</p>

            {underwriter ? (
              <ApplicationStatusBadge status={sellerApplication?.status} />
            ) : sellerApplication?.status !== "ON_REVIEW" ? (
              <NavLink to={"business-details"} href="#" className="link">
                <img src="/images/icons/pencil.svg" />
              </NavLink>
            ) : null}
          </div>
          <div className="main-content__title-item">
            {underwriter ? (
              <button
                className="input input--btn input--btn--plus"
                onClick={() => {
                  setFooter("comments");
                  animateScroll.scrollToBottom({
                    duration: 1000,
                    smooth: "easeInOutQuart",
                    containerId: "scroller",
                    offset: -35,
                  });
                }}
              >
                Add comment
              </button>
            ) : (
              <input
                type="button"
                value={(sellerApplication?.status || "Not created").replace(
                  /_/gi,
                  " "
                )}
                className={classNames(
                  `input input--btn input--btn--status-${(
                    sellerApplication?.status || "Not created"
                  )
                    .toLowerCase()
                    .replace(/_/gi, "-")}`
                )}
              />
            )}
          </div>
        </div>

        <div
          className={classNames("main-content__colums collapse", {
            active: active,
          })}
        >
          <hr className="line" />

          <button className="collapse" onClick={() => setActive(!active)}>
            <img src="/images/icons/collapse.svg" />
          </button>

          <div className="main-content__colums-item">
            <h3 className="title">Personal Information</h3>
            <p>
              {sellerApplication?.country || "-"},{" "}
              {sellerApplication?.region || "-"}
            </p>
            <p>{sellerApplication?.address || "-"}</p>
            <p>{sellerApplication?.phone?.number || "-"}</p>
            <a
              href={sellerApplication?.website || ""}
              target="_blank"
              className="txt--blue"
              rel="noreferrer"
            >
              {sellerApplication?.website || "-"}
            </a>
            <p>{sellerApplication?.platform?.name || "-"}</p>
            <p>{sellerApplication?.revenue?.name || "-"}</p>
            <p>
              Type of business:{" "}
              {capitalizeFirstLetter(
                (sellerApplication?.typeOfBusiness || "-").replace(/_/gi, " ")
              )}
            </p>
            {sellerApplication?.typeOfBusiness !== "CORPORATION" && (
              <p>
                Identification Number:{" "}
                {sellerApplication?.identificationNumber || "-"}
              </p>
            )}
          </div>
          <div className="main-content__colums-item">
            <h3 className="title">Bank details</h3>
            <p>Account for payouts: {sellerApplication?.bankId || "-"}</p>
            <p>Routing number: {sellerApplication?.routingNumber || "-"}</p>
            <p>Account number: {sellerApplication?.accountNumber || "-"}</p>
          </div>
          {sellerApplication?.typeOfBusiness === "CORPORATION" && (
            <div className="main-content__colums-item">
              <h3 className="title">Business structure</h3>
              <p>{sellerApplication?.legalBusinessName || "-"}</p>
              <p>{sellerApplication?.companyRegistrationNumber || "-"}</p>
              <p>{sellerApplication?.vatNumber || "-"}</p>
              <p>{sellerApplication?.doingBusinessAs || "-"}</p>
            </div>
          )}
        </div>

        <hr className="line" />

        <div className="main-content__item">
          <div className="title title--switch">
            {sellerApplication?.histories?.length ? (
              <button
                className={classNames("link", {
                  "link--active": footer === "history",
                })}
                onClick={() => setFooter("history")}
              >
                History
              </button>
            ) : null}

            {sellerApplication?.comments?.length || underwriter ? (
              <button
                className={classNames("link", {
                  "link--active": footer === "comments",
                })}
                onClick={() => setFooter("comments")}
              >
                Comments
              </button>
            ) : null}
          </div>

          {footer === "comments" &&
            (sellerApplication?.comments?.length || underwriter) && (
              <Comments
                underwriter={underwriter}
                comments={sellerApplication?.comments}
                id={sellerApplication.id}
              />
            )}

          {footer === "history" && sellerApplication?.histories?.length ? (
            <History histories={sellerApplication?.histories} />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default React.memo(SummaryContainer);

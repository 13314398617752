import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useMutation } from "@apollo/client";

import VirtualCardFields from "./VirtualCardFields";
import { CREATE_VIRTUAL_CARD } from "../../../queries/virtualCards";

export function NewVirtualCard({ businesses, getAllVirtualCards }) {
  const navigate = useNavigate();

  const [createVirtualCard, { loading }] = useMutation(CREATE_VIRTUAL_CARD, {
    onCompleted: ({ createVirtualCard: { success, virtualCard } }) => {
      if (success) {
        getAllVirtualCards();
        navigate(`/profile/cards/${virtualCard.id}`);
      }
    },
  });

  const initialValues = {
    businessId: businesses ? businesses[0]?.id : "",
    cardName: "",
    dollarLimit: "100",
  };

  const onSubmit = useCallback((values) => {
    createVirtualCard({
      variables: {
        input: values,
      },
    });
  });

  if (!loading) {
    return (
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="main-content__form">
            <VirtualCardFields title="Add Card" />
          </form>
        )}
      </Formik>
    );
  }

  return null;
}

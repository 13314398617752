/* eslint-disable no-unused-vars */
import classNames from "classnames";
import React, { useState } from "react";
import { useRef } from "react";
import style from "./style.module.scss";

const CustomSelect = ({ value, setValue, suffix, onlyValue = false }) => {
  const [status, setStatus] = useState(false);
  const ref = useRef();

  const onChange = (val) => {
    setStatus(false);
    setValue(val);
  };

  return (
    <div
      className={classNames(style.container, {
        [style.active]: status,
        [style.onlyValue]: onlyValue,
      })}
    >
      <div className={style.label} onClick={() => setStatus(!status)}>
        <div className={style.image}>
          <img src="/images/icons/table/bank.svg" />
        </div>
        <div className={style.txtacc}>
          BUS COMPLETE CHK •••• 4455 9875 641
          <br />
          <span>Checking - Chase</span>
        </div>

        {!onlyValue && (
          <img src="/images/icons/dropselect.svg" className={style.drop} />
        )}
      </div>

      {!onlyValue && status && (
        <div className={style.menu}>
          <div className={style.label} onClick={() => onChange(true)}>
            <div className={style.image}>
              <img src="/images/icons/table/bank.svg" />
            </div>
            <div className={style.txtacc}>
              BUS COMPLETE CHK •••• 4455 9875 641
              <br />
              <span>Checking - Chase</span>
            </div>
          </div>

          <div className={style.label} onClick={() => onChange(true)}>
            <div className={style.image}>
              <img src="/images/icons/table/bank.svg" />
            </div>
            <div className={style.txtacc}>
              BUS COMPLETE CHK •••• 4455 9875 641
              <br />
              <span>Checking - Chase</span>
            </div>
          </div>

          <div className={style.label} onClick={() => onChange(true)}>
            <div className={style.image}>
              <img src="/images/icons/table/bank.svg" />
            </div>
            <div className={style.txtacc}>
              BUS COMPLETE CHK •••• 4455 9875 641
              <br />
              <span>Checking - Chase</span>
            </div>
          </div>
        </div>
      )}

      {suffix && suffix}
    </div>
  );
};

export default CustomSelect;

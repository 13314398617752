/* eslint-disable no-unused-vars */
import { useMutation } from "@apollo/client";
import { Field, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  SEND_VERIFICATION_SMS,
  VERIFY_PHONE,
} from "../../../../queries/business-structure/queries";
import { Input, DigitCode } from "../../../../utils/s_formcontrol";
import Yup from "../../../../utils/validators";

const ChangePersonalPhone = () => {
  const { callbacks } = useSelector((state) => state.modals);
  const [error, setError] = useState(undefined);
  const [showDigit, setShowDigit] = useState(false);

  const initialValues = {
    phone_number: "",
    phone_verification_code: "",
    phone_verification: "",
  };

  const validationSchema = Yup.object().shape({
    phone_number: Yup.string().required("Field is required"),
    phone_verification_code: Yup.string()
      .required("Field is required")
      .min(6)
      .max(6),
  });

  const [sendVerificationSms, { loading: l1 }] = useMutation(
    SEND_VERIFICATION_SMS,
    {
      onError: (error) => {
        console.log(JSON.stringify(error));
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    }
  );

  const [verifyPhone, { loading: l2 }] = useMutation(VERIFY_PHONE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  const sendPhone = useCallback((number, callbackSuccess) => {
    sendVerificationSms({
      variables: {
        input: {
          phone: number,
          type: "USER",
        },
      },
      onCompleted: ({ sendVerificationSms: { success, errors } }) => {
        if (success) {
          setShowDigit(true);
        } else {
          setError(errors?.[0]?.message || "Unknow error");
        }
      },
      onError: (error) => {
        setError(JSON.stringify(error));
      },
    });
  }, []);

  const sendVerifyPhone = useCallback(
    (number, code, callbackSuccess, callbackError) => {
      verifyPhone({
        variables: {
          input: {
            phone: number,
            code: code,
          },
        },
        onCompleted: ({ verifyPhone: { success, errors } }) => {
          if (success) {
            callbacks.success();
          } else {
            setError(errors?.[0]?.message || "Unknow error");
          }
        },
        onError: (error) => {
          setError(JSON.stringify(error));
        },
      });
    },
    []
  );

  const onSubmit = (data) => {
    sendVerifyPhone(data.phone_number, data.phone_verification_code);
  };

  return (
    <Formik
      id="change_personal_phone"
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{ ...initialValues }}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        isValid,
        setFieldValue,
        setFieldError,
        values,
        dirty,
      }) => (
        <form
          onSubmit={handleSubmit}
          name="change_personal_phone"
          className="main-content__form"
        >
          <h2 className="form__el-headline">Phone</h2>

          {error && (
            <div className="message-errror">
              <div className="message-errror__pic"></div>
              <p>{error}</p>
            </div>
          )}

          <Field
            title={"Phone Number *"}
            name="phone_number"
            component={Input}
            sendBtnStatus={l1}
            disabled={values.phone_verification}
            sendBtnCallback={() => {
              setShowDigit(false);

              setFieldValue("phone_verification_code", "");
              setFieldValue("phone_verification", false);
              setFieldError("phone_verification", null);

              sendPhone(values.phone_number, () => {
                setShowDigit(true);
              });
            }}
          />

          {showDigit && (
            <Field
              title="Enter the code you received"
              component={DigitCode}
              status={values.phone_verification}
              disabled={l2}
              name="phone_verification_code"
            />
          )}

          <div className="alert-block__btns">
            <input
              type="button"
              value="Cancel"
              className="input input--btn input--btn--light"
              onClick={callbacks.finally}
            />

            <input
              disabled={l1 || l2 || !(isValid && dirty)}
              type="submit"
              value="Confirm"
              className="input input--btn"
            />
          </div>
        </form>
      )}
    </Formik>
  );
};

export default React.memo(ChangePersonalPhone);

/* eslint-disable no-unused-vars */
import { useMutation } from "@apollo/client";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { UPDATE_SELLER_STATUS } from "../../../queries/sellers/queries";
import { capitalizeFirstLetter } from "../../../services/helpers";
import StatusOption from "../../StatusOption";
import statusLabel from "../../../utils/statusLabel";

const StatusContainer = ({ id, status }) => {
  const [_status, setStatus] = useState(status);
  const [active, setActive] = useState(false);
  const componentRef = useRef(null);

  const [updateStatus, { loading }] = useMutation(UPDATE_SELLER_STATUS, {
    onCompleted: ({ updateSellerStatus: { success, sellerApplication } }) => {
      if (success) {
        setStatus(sellerApplication.status.toLowerCase());
        setActive(false);
      }
    },
    onError: (error) => {
      console.log(JSON.stringify(error));
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  const onChangeStatus = (status, e) => {
    e.stopPropagation();
    updateStatus({
      variables: {
        input: {
          sellerApplicationId: id,
          status,
        },
      },
    });
  };

  useEffect(() => {
    const clickOutside = (e) => {
      if (componentRef.current && !componentRef.current.contains(e.target)) {
        setActive(false);
      }
    };

    document.addEventListener("mousedown", clickOutside);

    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [componentRef]);

  return (
    <Status
      loading={loading}
      id={id}
      status={_status}
      active={active}
      setActive={setActive}
      componentRef={componentRef}
      onChangeStatus={onChangeStatus}
    />
  );
};

const Status = ({
  loading,
  status,
  active,
  setActive,
  componentRef,
  onChangeStatus,
}) => {
  return (
    <div
      className={classNames("main-table__main-status", {
        "popup--active": active,
        disabled: loading,
      })}
      ref={componentRef}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          setActive(!active);
        }}
        className={classNames(
          `link table-status table-status--${status
            .toLowerCase()
            .replace(/_/gi, "-")}`
        )}
      >
        {statusLabel(status)}
      </div>

      <div className="popup-menu">
        {[
          "pending",
          "approved_for_kyc",
          "kyc_accepted",
          "approved",
          "rejected_for_kyc",
          "kyc_declined",
          "declined",
        ].map((status) => (
          <StatusOption
            key={status}
            value={status}
            onChangeStatus={onChangeStatus}
          />
        ))}
      </div>
    </div>
  );
};

export default React.memo(StatusContainer);

import { createGlobalStyle } from "styled-components";
export const GlobalStyleVariables = createGlobalStyle`
  :root {
    --blue: #0066f2;
    --orange: #ff7e33;
    --yellow: #ffe600;
    --green: #47a04b;
    --red: #d94a56;    
  }
`;

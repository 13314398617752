import React from "react";
import { Routes, Route } from "react-router-dom";

import MotionComponent from "../../MotionComponent";
import WithVirtualCards from "./WithVirtualCards";
import VirtualCardList from "./VirtualCardList";
import { SplitView } from "../../SplitView";
import { NewVirtualCard } from "./NewVirtualCard";
import VirtualCard from "./VirtualCard";
import { EditVirtualCard } from "./EditVirtualCard";
import { VirtualCardsIndex } from "./VirtualCardIndex";

export default function VirtualCards() {
  return (
    <MotionComponent>
      <WithVirtualCards>
        {({ virtualCards = [], getAllVirtualCards, loading, businesses }) => (
          <SplitView>
            <VirtualCardList virtualCards={virtualCards} loading={loading} />

            <Routes>
              <Route
                index
                element={<VirtualCardsIndex virtualCards={virtualCards} />}
              />
              <Route
                path="new"
                element={
                  <NewVirtualCard
                    businesses={businesses}
                    getAllVirtualCards={getAllVirtualCards}
                  />
                }
              />
              <Route path=":virtualCardId" element={<VirtualCard />} />
              <Route
                path=":virtualCardId/edit"
                element={
                  <EditVirtualCard getAllVirtualCards={getAllVirtualCards} />
                }
              />
            </Routes>
          </SplitView>
        )}
      </WithVirtualCards>
    </MotionComponent>
  );
}

import React, { useCallback } from "react";
import { useState } from "react";
import FilterHOC from "./FilterHOC";

const CustomSelect = ({ name, value, setValue }) => {
  const [status, setStatus] = useState(false);

  const selectAll = useCallback(() => {
    const action = value.filter((d) => d.status).length === value.length;

    setValue(
      value.map((d) => {
        return { ...d, status: action ? false : true };
      })
    );
  }, [value]);

  const onChange = (key) => {
    setValue(
      value.map((e) => (e.value === key ? { ...e, status: !e?.status } : e))
    );
  };

  return (
    <FilterHOC setStatus={setStatus} status={status}>
      <span className="label">
        {`${name}: `}
        {value.filter((d) => d.status).length === value.length
          ? "All"
          : value.filter((e) => e.status)[0]?.label || "None"}
      </span>

      <div className="input-count">{value.filter((e) => e.status).length}</div>

      <label
        className="label-btn"
        htmlFor="status"
        onClick={() => setStatus(!status)}
      ></label>

      <div className="popup-menu popup-menu--status">
        <label className="popup-menu__item">
          <input
            onChange={selectAll}
            type="checkbox"
            className="input--checkbox"
            checked={
              value.filter((d) => d.status).length === value.length || false
            }
          />
          <span className="txt">All</span>
        </label>

        {value.map((e, i) => (
          <label className="popup-menu__item" key={i}>
            <input
              onChange={() => onChange(e.value)}
              type="checkbox"
              className="input--checkbox"
              checked={e.status || false}
            />
            <span className="txt">{e.label}</span>
          </label>
        ))}
      </div>
    </FilterHOC>
  );
};

export default CustomSelect;

import { gql } from "@apollo/client";
import { CORE_ERROR_FIELDS, CORE_USER_FIELDS } from "../user/fragments";

export const CORE_EMAIL_CONFIRMATION_FIELDS = gql`
  ${CORE_ERROR_FIELDS}
  ${CORE_USER_FIELDS}

  fragment CoreEmailConfirmation on EmailConfirmation {
    token
    user {
      ...CoreUser
    }
    success
    errors {
      ...CoreErrorFields
    }
  }
`;

export const CORE_GENERATE_FORGOT_PASSWORD_TOKEN_FIELDS = gql`
  ${CORE_ERROR_FIELDS}
  ${CORE_USER_FIELDS}

  fragment CoreGenerateForgotPasswordToken on GenerateForgotPasswordToken {
    user {
      ...CoreUser
    }
    success
    errors {
      ...CoreErrorFields
    }
  }
`;

export const CORE_FORGOT_PASSWORD_TOKEN_FIELDS = gql`
  ${CORE_ERROR_FIELDS}
  ${CORE_USER_FIELDS}

  fragment CoreForgotPassword on ForgotPassword {
    user {
      ...CoreUser
    }
    success
    errors {
      ...CoreErrorFields
    }
  }
`;

export const CORE_UPDATE_FORGOTED_PASSWORD_FIELDS = gql`
  ${CORE_ERROR_FIELDS}
  ${CORE_USER_FIELDS}

  fragment CoreUpdateForgotedPassword on UpdateForgotedPassword {
    token
    user {
      ...CoreUser
    }
    success
    errors {
      ...CoreErrorFields
    }
  }
`;

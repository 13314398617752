import React, { useCallback, useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import types from "../../../../../redux/types";
import Preloader from "../../../../Base/Preloader";

const SettingsBodyContainer = ({ setStatus }) => {
  const settRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state.auth);
  const { role, firstName, lastName } = useSelector((state) => state.auth.data);

  const logoutSubmit = useCallback(() => {
    dispatch({
      type: types.SIGN_OUT_START,
      payload: {
        callbackSuccess: () => navigate("/auth/signin"),
      },
    });
  }, []);

  useEffect(() => {
    const clickOutside = (e) => {
      if (settRef.current && !settRef.current.contains(e.target)) {
        setStatus(false);
      }
    };

    document.addEventListener("mousedown", clickOutside);

    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [settRef]);

  return (
    <SettingsBody
      loader={loader}
      settRef={settRef}
      logoutSubmit={logoutSubmit}
      callBack={() => setStatus(false)}
      role={role}
      name={`${firstName} ${lastName}`}
    />
  );
};

const SettingsBody = ({
  loader,
  settRef,
  logoutSubmit,
  callBack,
  role,
  name,
}) => {
  return (
    <div className="settings" ref={settRef}>
      {loader ? <Preloader /> : null}
      <div className="settings-header">
        <div className="settings-header__photo"></div>
        <div className="settings-header__info">
          <span className="settings-header__info-name">{name}</span>
          <span>{role}</span>
        </div>
      </div>

      <NavLink to="/settings" className="link settings-link" onClick={callBack}>
        <img
          src="/images/icons/settings/profile.svg"
          className="settings-link__img"
        />
        <img
          src="/images/icons/settings/profile_active.svg"
          className="settings-link__img--active"
        />
        Profile
      </NavLink>

      {role === "SELLER" && (
        <>
          <NavLink to="#" className="link settings-link">
            <img
              src="/images/icons/settings/team.svg"
              className="settings-link__img"
            />
            <img
              src="/images/icons/settings/team_active.svg"
              className="settings-link__img--active"
            />
            Team
          </NavLink>

          <NavLink to="#" className="link settings-link">
            <img
              src="/images/icons/settings/docs_statements.svg"
              className="settings-link__img"
            />
            <img
              src="/images/icons/settings/docs_statements_active.svg"
              className="settings-link__img--active"
            />
            Docs &amp; statements
          </NavLink>

          <NavLink to="#" className="link settings-link">
            <img
              src="/images/icons/settings/data_export.svg"
              className="settings-link__img"
            />
            <img
              src="/images/icons/settings/data_export_active.svg"
              className="settings-link__img--active"
            />
            Data export
          </NavLink>
        </>
      )}

      <NavLink to="#" className="link settings-link" onClick={logoutSubmit}>
        <img
          src="/images/icons/settings/log_out.svg"
          className="settings-link__img"
        />
        <img
          src="/images/icons/settings/log_out_active.svg"
          className="settings-link__img--active"
        />
        Log Out
      </NavLink>
    </div>
  );
};

export default React.memo(SettingsBodyContainer);

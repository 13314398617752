import React, { useState } from "react";
import { useDispatch } from "react-redux";
import types from "../../../../redux/types";
import LeftSideBar from "./LeftSideBar";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import style from "./style.module.scss";

const TransferContainer = ({ closeMoveMoney, openMoveMoney }) => {
  const [step, setStep] = useState(1);

  return (
    <Transfer
      closeMoveMoney={closeMoveMoney}
      openMoveMoney={openMoveMoney}
      step={step}
      setStep={setStep}
    />
  );
};

const Transfer = ({ closeMoveMoney, openMoveMoney, step, setStep }) => {
  const dispatch = useDispatch();

  const modalClose = () =>
    dispatch({
      type: types.HIDE_MODAL,
    });

  const onEnd = () => {
    closeMoveMoney();

    dispatch({
      type: types.SHOW_MODAL,
      payload: {
        name: "TransferStarted",
        callbacks: {
          onNext: () => {
            modalClose();
            openMoveMoney();
          },
          finally: () => modalClose(),
          success: () => modalClose(),
        },
      },
    });
  };

  return (
    <div className={style.container}>
      <LeftSideBar step={step} />

      <div className={style.content}>
        <div className={style.title}>
          <h2>
            {step === 1 && "Transfer between your accounts"}
            {step === 2 && "Transfering"}
          </h2>

          <div className={style.btn} onClick={closeMoveMoney}>
            <img src="/images/icons/transferclose.svg"></img>
          </div>
        </div>

        {step === 1 && (
          <Step1 prevStep={closeMoveMoney} nextStep={() => setStep(step + 1)} />
        )}

        {step === 2 && (
          <Step2 prevStep={() => setStep(step - 1)} nextStep={onEnd} />
        )}
      </div>
    </div>
  );
};

export default React.memo(TransferContainer);

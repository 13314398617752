import { gql } from "@apollo/client";

export const CORE_PHONE_NUMBER = gql`
  fragment CorePhone on Phone {
    number
    verified
  }
`;

export const CORE_USER_FIELDS = gql`
  ${CORE_PHONE_NUMBER}

  fragment CoreUser on User {
    approvedSeller
    id
    firstName
    kycVerificationStatus
    lastName
    email
    role
    unconfirmedEmail
    sellerApplicationStatus
    phone {
      ...CorePhone
    }
  }
`;

export const CORE_ERROR_FIELDS = gql`
  fragment CoreErrorFields on Error {
    message
    path
  }
`;

export const CORE_KYC_VERIFICATION = gql`
  fragment CoreKycVerification on KycVerification {
    link
  }
`;

export const CORE_UPDATE_USER_FIELDS = gql`
  ${CORE_ERROR_FIELDS}
  ${CORE_USER_FIELDS}

  fragment CoreUpdateUserProfile on UpdateUserProfile {
    success
    errors {
      ...CoreErrorFields
    }
    user {
      ...CoreUser
    }
  }
`;

export const CORE_UPDATE_EMAIL_FIELDS = gql`
  ${CORE_ERROR_FIELDS}
  ${CORE_USER_FIELDS}

  fragment CoreUpdateUserEmail on UpdateUserEmail {
    success
    errors {
      ...CoreErrorFields
    }
    user {
      ...CoreUser
    }
  }
`;

export const CORE_UPDATE_PASSWORD_FIELDS = gql`
  ${CORE_ERROR_FIELDS}
  ${CORE_USER_FIELDS}

  fragment CoreUpdateUserPassword on UpdateUserPassword {
    success
    errors {
      ...CoreErrorFields
    }
    user {
      ...CoreUser
    }
  }
`;

/* eslint-disable no-unused-vars */
import { useMutation } from "@apollo/client";
import { Field, Formik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  UPDATE_EMAIL_PROFILE,
  UPDATE_PASSWORD_PROFILE,
} from "../../../../queries/user/queries";
import { getChangedValues } from "../../../../services/helpers";
import { Input, InputNewPassword } from "../../../../utils/formcontrol";
import Yup from "../../../../utils/validators";

const ChangePersonalPasswordContainer = () => {
  const { callbacks } = useSelector((state) => state.modals);
  const [error, setError] = useState(undefined);

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
  };

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Field is required").zxcvbnType(),
    newPassword: Yup.string().required("Field is required").zxcvbnType(),
    newPasswordConfirmation: Yup.string()
      .required("Field is required")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.newPassword === value;
      }),
  });

  const [updatePassword, { loading }] = useMutation(UPDATE_PASSWORD_PROFILE, {
    onCompleted: ({ updatePassword: { success, errors } }) => {
      if (success) {
        callbacks.success();
      } else {
        setError(errors?.[0]?.message || "Unknow error");
      }
    },
    onError: (error) => {
      setError(JSON.stringify(error));
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  const onSubmit = (data) => {
    setError(undefined);
    const changed = getChangedValues({ ...data }, initialValues);

    if (Object.keys(changed).length) {
      updatePassword({
        variables: {
          input: { ...data },
        },
      });
    } else {
      callbacks.finally();
    }
  };

  return (
    <ChangePersonalPassword
      callbacks={callbacks}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      loading={loading}
      error={error}
    />
  );
};

const ChangePersonalPassword = ({
  callbacks,
  initialValues,
  validationSchema,
  onSubmit,
  loading,
  error,
}) => {
  return (
    <>
      <Formik
        id="change_personal_email"
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{ ...initialValues }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isValid, dirty }) => (
          <form
            onSubmit={handleSubmit}
            name="change_personal_email"
            className="main-content__form"
          >
            <h2 className="form__el-headline">Password</h2>

            {error && (
              <div className="message-errror">
                <div className="message-errror__pic"></div>
                <p>{error}</p>
              </div>
            )}

            <Field
              type="password"
              title={"Current Password"}
              name="currentPassword"
              component={Input}
            />

            <Field
              type="password"
              title={"New Password"}
              name="newPassword"
              component={InputNewPassword}
              autoComplete="new-password"
            />

            <Field
              type="password"
              title={"New Password Confirmation"}
              name="newPasswordConfirmation"
              component={Input}
              autoComplete="new-password"
            />

            <div className="alert-block__btns">
              <input
                type="button"
                value="Cancel"
                className="input input--btn input--btn--light"
                onClick={callbacks.finally}
              />

              <input
                disabled={loading || !(isValid && dirty)}
                type="submit"
                value="Confirm"
                className="input input--btn"
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default React.memo(ChangePersonalPasswordContainer);

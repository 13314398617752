import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const TransferStarted = () => {
  const { callbacks } = useSelector((state) => state.modals);

  return (
    <>
      <img src="/images/icons/alert_check.svg" className="alert-block__img" />
      <h2 className="alert-block__title transfer">
        You’ve started a $ 1.00 transfer
        <br />
        from BUS COMPLETE CHK ••••6204
        <br />
        to Cheking ••2652
      </h2>

      <div className="alert-block__title_text">
        The money should arrive within 3 business days.
      </div>

      <NavLink
        className="alert-block__button"
        to="/"
        onClick={callbacks.success}
      >
        Go to dashboard
      </NavLink>

      <div className="alert-block__link" onClick={callbacks.onNext}>
        Make another transfer
      </div>
    </>
  );
};

export default TransferStarted;

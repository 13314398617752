import React from "react";
import classNames from "classnames";

import Header from "./Pages/Profile/Header";
import LeftBar from "./Pages/Profile/LeftBar";

export default function AuthenticatedApp({ routing, embedded }) {
  return (
    <>
      <div className={classNames("body-grey")}></div>
      {!embedded && <Header />}
      <main className="main wrapper">
        {!embedded && <LeftBar />}
        <section className="main-box" id="scroller">
          {routing}
        </section>
      </main>
    </>
  );
}

import React from "react";

const ChangesSavedEmail = () => {
  return (
    <>
      <img src="/images/icons/alert_check.svg" className="alert-block__img" />
      <h2 className="alert-block__title">
        Your changes have been successfully saved!
      </h2>
      Your email will be update after confirmation. Please check your inbox and
      follow the instruction
    </>
  );
};

export default ChangesSavedEmail;

import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import styled from "styled-components";

import { MaskedCVV } from "./MaskedCVV";

import { GET_VIRTUAL_CARD } from "../../../queries/virtualCards";
import { formattedCardNumber } from "../../../utils/virtualCards";

export default function VirtualCard() {
  const { virtualCardId } = useParams();

  const [getVirtualCard, { data: { virtualCard } = {} }] = useLazyQuery(
    GET_VIRTUAL_CARD,
    {
      variables: { virtualCardId: virtualCardId },
    }
  );

  useEffect(() => {
    getVirtualCard();
  }, [virtualCardId]);

  if (virtualCard) {
    const { cardName, cardNumber, cvv, expirationDate, dollarLimit } =
      virtualCard;

    return (
      <div style={{ flex: `1` }}>
        <PageHeader>
          <h1>{cardName || "Unnamed Card"}</h1>
          <Link to="edit">Edit card</Link>
        </PageHeader>

        <table>
          <thead>
            <tr>
              <th>Card Number</th>
              <th>Expiration</th>
              <th>CVV</th>
              <th>Limit</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{formattedCardNumber(cardNumber)}</td>
              <td>{expirationDate}</td>
              <td>
                <MaskedCVV cvv={cvv} />
              </td>
              <td>{dollarLimit}</td>
              <td>Virtual</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return <div>Loading...</div>;
}

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 1rem;

  h1 {
    color: var(--blue);
  }

  a {
    border: 1px solid #333;
    padding: 0.5rem 1rem;
    color: #333;
    text-decoration: none;
  }
`;

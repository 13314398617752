import { useMutation } from "@apollo/client";
import { Field, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { UPDATE_FORGOTED_PASSWORD } from "../../../../../queries/auth/queries";
import types from "../../../../../redux/types";
import { Input, InputNewPassword } from "../../../../../utils/formcontrol";
import Yup from "../../../../../utils/validators";

import RollBack from "../../../../Base/RollBack";

const CreateNewPasswordContainer = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const { token } = useParams();

  const [updateForgotedPassword, { loading }] = useMutation(
    UPDATE_FORGOTED_PASSWORD,
    {
      onCompleted: ({
        updateForgotedPassword: { success, errors, token, user },
      }) => {
        if (success) {
          dispatch({
            type: types.SIGN_IN_SUCCESS,
            payload: {
              token: token,
              first_sign: false,
              ...user,
            },
          });
        } else {
          setError(errors?.[0]?.message || "Unknow error");
        }
      },
      onError: (error) => {
        setError(JSON.stringify(error));
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    }
  );

  const onSubmit = useCallback((data) => {
    updateForgotedPassword({
      variables: {
        input: {
          ...data,
          restoreToken: token,
        },
      },
    });
  }, []);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Field is required").zxcvbnType(),
    confirmPassword: Yup.string().test(
      "passwords-match",
      "Passwords must match",
      function (value) {
        return this.parent.password === value;
      }
    ),
  });

  return (
    <CreateNewPassword
      error={error}
      loader={loading}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    />
  );
};

const CreateNewPassword = ({
  error,
  loader,
  onSubmit,
  initialValues,
  validationSchema,
}) => {
  return (
    <>
      <RollBack />

      <div className="box">
        <NavLink to={"/"} className="box-logo"></NavLink>

        <h2 className="box-title box-title--small" style={{ marginBottom: 60 }}>
          Create new password
        </h2>

        <div className="form-wrapper form-wrapper--indent">
          <Formik
            id="sign_in"
            enableReinitialize
            initialValues={{ ...initialValues }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isValid, touched }) => (
              <form
                onSubmit={handleSubmit}
                name="new-password"
                className="form"
              >
                {error && (
                  <div className="message-errror">
                    <div className="message-errror__pic"></div>
                    <p>{error}</p>
                  </div>
                )}

                <Field
                  type="password"
                  title={"New Password *"}
                  name="password"
                  component={InputNewPassword}
                  autoComplete="new-password"
                />

                <Field
                  type="password"
                  title={"Repeat Password *"}
                  name="confirmPassword"
                  component={Input}
                  autoComplete="new-password"
                />

                <input
                  type="submit"
                  value="Sign Up"
                  className="input input--btn"
                  disabled={!(isValid && Object.keys(touched).length) || loader}
                />
              </form>
            )}
          </Formik>
        </div>

        <div className="box-bottom">
          <div className="form-wrapper">
            <hr className="line" />
            <p className="box-bottom__txt">
              If you have any problem{" "}
              <NavLink to={"/"} className="link txt--blue">
                Contact Us
              </NavLink>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(CreateNewPasswordContainer);

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import types from "../../../../redux/types";
import Preloader from "../../../Base/Preloader";

const TokenSignIn = () => {
  const { id, confirmationToken, redirectPath, embedded } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State to track if an error occurs
  const [error, setError] = useState(null);

  useEffect(() => {
    // Check for existence and validity of params
    if (!id || !confirmationToken || !redirectPath || !embedded) {
      setError("Invalid parameters provided");
      return;
    }
    try {
      // Dispatch action
      dispatch({
        type: types.SIGN_IN_SUCCESS,
        payload: {
          token: confirmationToken,
          first_sign: false,
          user_id: id,
          embedded: embedded === "true",
        },
      });

      // Navigate after dispatch
      navigate("/profile/" + redirectPath);
    } catch (error) {
      // Handle any errors here
      setError("Error:", error);
    }
  }, [dispatch, id, confirmationToken, redirectPath, embedded, navigate]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Preloader />
    </>
  );
};

export default React.memo(TokenSignIn);

import classNames from "classnames";
import React, { useContext } from "react";
import { AccountContext } from "..";
import style from "./style.module.scss";

const NavBar = () => {
  const { tab, setTab, tabList } = useContext(AccountContext);

  return (
    <div className={style.nav}>
      {tabList.map((t) => (
        <button
          key={t}
          className={classNames({ [style.active]: tab === t })}
          onClick={() => setTab(t)}
        >
          {t}
        </button>
      ))}
    </div>
  );
};

export default NavBar;

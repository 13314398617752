export default function statusLabel(value) {
  const statuses = {
    pending: "Pending",
    approved_for_kyc: "Approved for KYC",
    kyc_accepted: "KYC Accepted",
    approved: "Approved",
    rejected_for_kyc: "Rejected for KYC",
    kyc_declined: "KYC Declined",
    declined: "Declined",
  };

  return statuses[value?.toLowerCase()];
}

/* eslint-disable no-unused-vars */
import { useMutation } from "@apollo/client";
import { Field, Formik, useFormikContext } from "formik";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { scroller } from "react-scroll";
import {
  SEND_VERIFICATION_SMS,
  VERIFY_PHONE,
} from "../../../../queries/business-structure/queries";

import {
  DigitCode,
  Input,
  InputPhoneNumber,
  Radio,
  Select,
} from "../../../../utils/s_formcontrol";
import Yup from "../../../../utils/validators";

const PersonalInformationContainer = ({
  sellerApplication,
  fields,
  onChange,
}) => {
  const { validated } = useSelector((state) => state.businessDetails);

  const _submitEnable = useMemo(
    () => validated?.personal,
    [validated?.personal]
  );

  const initialValues = {
    country: sellerApplication?.country || "",
    region: sellerApplication?.region || "",
    address: sellerApplication?.address || "",

    phone_number:
      sellerApplication?.phone?.number || sellerApplication?.phone_number || "",
    phone_verification_code: sellerApplication?.phone_verification_code || "",
    phone_verification:
      sellerApplication?.phone_verification ||
      sellerApplication?.phone?.verified ||
      false,

    website: sellerApplication?.website || "",
    platformId:
      sellerApplication?.platform?.id || sellerApplication?.platformId || "",
    revenueId:
      sellerApplication?.revenue?.id || sellerApplication?.revenueId || "",
    typeOfBusiness: sellerApplication?.typeOfBusiness || "SOLE_PROPRIETORSHIP",
    identificationNumber: sellerApplication?.identificationNumber || "",
  };

  const validationSchema = Yup.object().shape({
    country: Yup.string().required("Field is required"),
    region: Yup.string().required("Field is required"),
    address: Yup.string().required("Field is required"),

    phone_number: Yup.string()
      .required("Field is required")
      .matches(
        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
        "Phone number is not valid"
      ),
    phone_verification_code: Yup.string(),
    phone_verification: Yup.bool()
      .required("Field is required")
      .oneOf([true], "Phone bust be verified"),

    website: Yup.string()
      .required("Field is required")
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      ),
    platformId: Yup.string().required("Field is required"),
    revenueId: Yup.string().required("Field is required"),
    typeOfBusiness: Yup.string().required("Field is required"),
    identificationNumber: Yup.string().when("typeOfBusiness", {
      is: (typeOfBusiness) => typeOfBusiness === "SOLE_PROPRIETORSHIP",
      then: Yup.string().required("Field is required"),
    }),
  });

  const [sendVerificationSms, { loading: l1 }] = useMutation(
    SEND_VERIFICATION_SMS,
    {
      onError: (error) => {
        console.log(JSON.stringify(error));
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    }
  );

  const [verifyPhone, { loading: l2 }] = useMutation(VERIFY_PHONE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  const sendPhone = useCallback((number, callbackSuccess, callbackError) => {
    sendVerificationSms({
      variables: {
        input: {
          phone: number,
          type: "COMPANY",
        },
      },
      onCompleted: ({ sendVerificationSms: { success, errors } }) => {
        if (success) {
          callbackSuccess();
        } else {
          callbackError(errors?.[0]?.message || "Unknow error");
        }
      },
      onError: (error) => {
        callbackError(JSON.stringify(error));
      },
    });
  }, []);

  const sendVerifyPhone = useCallback(
    (number, code, callbackSuccess, callbackError) => {
      verifyPhone({
        variables: {
          input: {
            phone: number,
            code: code,
          },
        },
        onCompleted: ({ verifyPhone: { success, errors } }) => {
          if (success) {
            callbackSuccess();
          } else {
            callbackError(errors?.[0]?.message || "Unknow error");
          }
        },
        onError: (error) => {
          callbackError("Unknow error: 500");
        },
      });
    },
    []
  );

  return (
    <PersonalInformation
      _submitEnable={_submitEnable}
      status={sellerApplication?.status}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onChange={onChange}
      sendPhone={sendPhone}
      sendVerifyPhone={sendVerifyPhone}
      fields={fields}
      l1={l1}
      l2={l2}
    />
  );
};

const MyOnChangeComponent = ({ onChange }) => {
  const didMount = useRef(false);
  const { values, isValid, errors } = useFormikContext();

  useEffect(() => {
    if (didMount.current)
      onChange({
        ...values,
        validated: isValid,
        form: "personal",
      });
    else didMount.current = true;
  }, [values, isValid, errors]);

  return null;
};

const PersonalInformation = ({
  _submitEnable,
  status,
  initialValues,
  validationSchema,
  onChange,
  sendPhone,
  sendVerifyPhone,
  fields,
  l1,
  l2,
}) => {
  const [showDigit, setShowDigit] = useState(false);

  const onScroll = () => {
    scroller.scrollTo(
      document.getElementById("business_details_sc")
        ? "business_details_sc"
        : "bank_details_sc",
      {
        duration: 1000,
        smooth: "easeInOutQuart",
        containerId: "scroller",
        offset: -35,
      }
    );
  };

  return (
    <div
      className="main-content__container main-content__container--first main-content__container__active"
      id="personal_information_sc"
    >
      <h2 className="main-content__title">Profile Information</h2>

      {status === "APPROVED" ? (
        <div className="message-errror">
          <div className="message-errror__pic"></div>
          <p>
            You are editing verified business details. You will need to resubmit
            it for review
          </p>
        </div>
      ) : null}

      <div className="main-content__block">
        <div className="main-content__block-info">
          <p className="txt">
            The information Stripe collets about your business helps us meet
            requirements from regulators, financial partners and our{" "}
            <span className="txt--blue">Services Agreement.</span>
          </p>
          <div className="main-content__block-info-img">
            <img
              src="/images/icons/business_structure/block_1_1of3.svg"
              className="main-content__img"
            />
            <img
              src="/images/icons/business_structure/block_1_1of2.svg"
              className="main-content__img main-content__img--sole-proprietorship"
            />
            <div className="main-content__block-info-img-btns">
              <button className="button" disabled>
                <img src="/images/icons/business_structure/arrow_left.svg" />
              </button>
              <button className="button" onClick={onScroll}>
                <img src="/images/icons/business_structure/arrow_right.svg" />
              </button>
            </div>
          </div>
        </div>

        <div className="main-content__block-form">
          <Formik
            id="personal_information"
            enableReinitialize
            initialValues={{ ...initialValues }}
            validationSchema={validationSchema}
            validateOnMount={true}
          >
            {({
              handleSubmit,
              values,
              touched,
              setFieldValue,
              setFieldError,
            }) => (
              <form
                onSubmit={handleSubmit}
                name="personal_information"
                className="form"
              >
                <MyOnChangeComponent onChange={onChange} />

                <Field
                  title={"Country *"}
                  name="country"
                  component={Select}
                  options={fields?.countries?.map((b) => {
                    return {
                      name: b.name,
                      value: b.code,
                    };
                  })}
                />

                <Field
                  title={"Region/City *"}
                  name="region"
                  component={Select}
                  options={fields?.countries
                    ?.filter((c) => c.code === values.country)?.[0]
                    ?.subdivisions?.map((b) => {
                      return {
                        name: b.name,
                        value: b.name,
                      };
                    })}
                />

                <Field
                  title={"Address: street, build etc. *"}
                  name="address"
                  component={Input}
                />

                <Field title={"Website *"} name="website" component={Input} />

                <Field
                  title={"Platform *"}
                  name="platformId"
                  component={Select}
                  options={fields?.platforms?.map((b) => {
                    return {
                      name: b.name,
                      value: b.id,
                    };
                  })}
                />

                <Field
                  title={"Revenue *"}
                  name="revenueId"
                  component={Select}
                  options={fields?.revenues?.map((b) => {
                    return {
                      name: b.name,
                      value: b.id,
                    };
                  })}
                />

                <Field
                  title={"Type of business *"}
                  name="typeOfBusiness"
                  component={Radio}
                  controls={[
                    {
                      id: "SOLE_PROPRIETORSHIP",
                      name: "Sole-Proprietorship",
                    },
                    {
                      id: "CORPORATION",
                      name: "Corporation",
                    },
                  ]}
                />

                {values.typeOfBusiness === "SOLE_PROPRIETORSHIP" && (
                  <Field
                    title={"Identification Number *"}
                    name="identificationNumber"
                    component={Input}
                  />
                )}

                {values.phone_number.length ? (
                  <p className="form__message">
                    You will receive a verification code
                  </p>
                ) : null}

                <Field
                  title={"Phone Number *"}
                  name="phone_number"
                  component={InputPhoneNumber}
                  sendBtnStatus={l1}
                  disabled={values.phone_verification}
                  sendBtnCallback={() => {
                    setShowDigit(false);

                    setFieldValue("phone_verification_code", "");
                    setFieldValue("phone_verification", false);
                    setFieldError("phone_verification", null);

                    sendPhone(
                      values.phone_number,
                      () => {
                        setShowDigit(true);
                      },
                      (error) => setFieldError("phone_number", error)
                    );
                  }}
                />

                {showDigit && (
                  <Field
                    title="Enter the code you received"
                    component={DigitCode}
                    autoSubmit={true}
                    status={values.phone_verification}
                    disabled={l2}
                    onSubmit={() =>
                      sendVerifyPhone(
                        values.phone_number,
                        values.phone_verification_code,
                        () => {
                          setFieldValue("phone_verification", true);
                        },
                        (error) => {
                          setFieldError("phone_verification_code", error);
                        }
                      )
                    }
                    name="phone_verification_code"
                  />
                )}

                <input
                  type="button"
                  value="Next"
                  disabled={!_submitEnable}
                  className="input input--btn"
                  onClick={onScroll}
                />
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PersonalInformationContainer);

import { useSelector } from "react-redux";

import CurrentStatusDescription from "./CurrentStatusDescription";
import NextStepButton from "./NextStepButton";

export default function WelcomeCard({ onPersonalIdentity }) {
  const {
    sellerApplicationStatus,
    firstName,
    kycVerificationStatus,
    approvedSeller,
  } = useSelector((state) => state.auth.data);

  if (firstName) {
    return (
      <div className="main-content--bricks--small bricks-content">
        <h3 className="bricks-title">Company Name</h3>
        <h2 className="bricks-content-title">
          Welcome,
          <span>{firstName}!</span>
        </h2>
        <div className="bricks-content-txt">
          <CurrentStatusDescription status={sellerApplicationStatus} />
        </div>

        <NextStepButton
          status={sellerApplicationStatus}
          kycVerificationStatus={kycVerificationStatus}
          onPersonalIdentity={onPersonalIdentity}
          approvedSeller={approvedSeller}
        />
      </div>
    );
  }

  return null;
}

/* eslint-disable no-unused-vars */
import "react-calendar/dist/Calendar.css";

import React, { useEffect } from "react";
import { useState } from "react";
import { Calendar } from "react-calendar";
import FilterHOC from "./FilterHOC";
import { useMemo } from "react";
import classNames from "classnames";

const CustomDate = ({ date, setDate }) => {
  const [status, setStatus] = useState(false);

  const formatDate = (date) => {
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    return `${dd}-${mm}-${yyyy}`;
  };

  const filterStatus = useMemo(() => {
    if (date?.[0] && date?.[1]) {
      const difference = Math.ceil(
        Math.abs(date[1].getTime() - date[0].getTime()) / (1000 * 3600 * 24)
      );

      switch (difference) {
        case 0:
          return "day";

        case 7:
          return "week";

        case 30:
          return "month";

        case 365:
          return "year";

        default:
          return "custom";
      }
    }
    return "all";
  }, [date]);

  const onFilter = (dateRange) => {
    const date = new Date();

    switch (dateRange) {
      case "day":
        setDate([new Date(), new Date()]);
        break;

      case "week":
        setDate([new Date(date.setDate(date.getDate() - 7)), new Date()]);
        break;

      case "month":
        setDate([new Date(date.setMonth(date.getMonth() - 1)), new Date()]);
        break;

      case "year":
        setDate([
          new Date(date.setFullYear(date.getFullYear() - 1)),
          new Date(),
        ]);
        break;

      case "all":
        setDate(null);
        break;
    }
  };

  return (
    <FilterHOC status={status} setStatus={setStatus}>
      <span className="label">
        Date:{" "}
        {filterStatus === "all"
          ? "All"
          : `${formatDate(date?.[0] || date)} - ${formatDate(
              date?.[1] || date
            )}`}
      </span>

      <label
        className="label-btn"
        htmlFor="status"
        onClick={() => setStatus(!status)}
      ></label>

      <div className="popup-menu popup-menu--date">
        <div
          className="react-calendar react-calendar__navigation filters"
          style={filterStatus === "all" ? { marginBottom: 0 } : {}}
        >
          <button
            className={classNames(
              "react-calendar__navigation__arrow react-calendar__navigation__prev-button",
              { "react-calendar__tile--hasActive": filterStatus === "day" }
            )}
            onClick={() => onFilter("day")}
          >
            Day
          </button>

          <button
            className={classNames(
              "react-calendar__navigation__arrow react-calendar__navigation__prev-button",
              { "react-calendar__tile--hasActive": filterStatus === "week" }
            )}
            onClick={() => onFilter("week")}
          >
            Week
          </button>

          <button
            className={classNames(
              "react-calendar__navigation__arrow react-calendar__navigation__prev-button",
              { "react-calendar__tile--hasActive": filterStatus === "month" }
            )}
            onClick={() => onFilter("month")}
          >
            Month
          </button>

          <button
            className={classNames(
              "react-calendar__navigation__arrow react-calendar__navigation__prev-button",
              { "react-calendar__tile--hasActive": filterStatus === "year" }
            )}
            onClick={() => onFilter("year")}
          >
            Year
          </button>

          <button
            className={classNames(
              "react-calendar__navigation__arrow react-calendar__navigation__prev-button",
              { "react-calendar__tile--hasActive": filterStatus === "all" }
            )}
            onClick={() => onFilter("all")}
          >
            All
          </button>
        </div>

        {filterStatus === "all" ? null : (
          <Calendar
            onChange={setDate}
            value={date || new Date()}
            returnValue={"range"}
            selectRange={true}
            calendarType="US"
          />
        )}
      </div>
    </FilterHOC>
  );
};

export default CustomDate;

import classNames from "classnames";
import React from "react";
import style from "./style.module.scss";

const Title = ({ title, children }) => {
  return (
    <div className={classNames(style.container, { [style.flex]: !!children })}>
      <h2 className={style.title}>{title}</h2>

      {children}
    </div>
  );
};

export default Title;

import classNames from "classnames";
import { Field, Formik, useFormikContext } from "formik";
import React, { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { scroller } from "react-scroll";
import { Input } from "../../../../utils/s_formcontrol";
import Yup from "../../../../utils/validators";

const BusinessDetailsContainer = ({ sellerApplication, onChange }) => {
  const { validated } = useSelector((state) => state.businessDetails);

  const _submitEnable = useMemo(
    () => validated?.personal,
    [validated?.personal]
  );

  const initialValues = {
    legalBusinessName: sellerApplication?.legalBusinessName || "",
    companyRegistrationNumber:
      sellerApplication?.companyRegistrationNumber || "",
    vatNumber: sellerApplication?.vatNumber || "",
    doingBusinessAs: sellerApplication?.doingBusinessAs || "",
  };

  const validationSchema = Yup.object().shape({
    legalBusinessName: Yup.string().required("Field is required"),
    companyRegistrationNumber: Yup.string().required("Field is required"),
    vatNumber: Yup.string(),
    doingBusinessAs: Yup.string(),
  });

  return (
    <BusinessDetails
      initialValues={initialValues}
      validationSchema={validationSchema}
      onChange={onChange}
      _submitEnable={_submitEnable}
    />
  );
};

const MyOnChangeComponent = ({ onChange }) => {
  const didMount = useRef(false);
  const { values, isValid } = useFormikContext();

  useEffect(() => {
    if (didMount.current)
      onChange({
        ...values,
        validated: isValid,
        form: "business_details",
      });
    else didMount.current = true;

    return () => {
      onChange({
        validated: true,
        form: "business_details",
      });
    };
  }, [values, isValid]);

  return null;
};

const BusinessDetails = ({
  initialValues,
  validationSchema,
  onChange,
  _submitEnable,
}) => {
  const onScroll = (side) => () => {
    scroller.scrollTo(
      side === "next" ? "bank_details_sc" : "personal_information_sc",
      {
        duration: 1000,
        smooth: "easeInOutQuart",
        containerId: "scroller",
        offset: side === "next" ? 0 : -35,
      }
    );
  };

  return (
    <div
      className={classNames(
        "main-content__container main-content__container--third",
        { "main-content__container__active": _submitEnable }
      )}
      id="business_details_sc"
    >
      <h2 className="main-content__title">Business details</h2>
      <div className="main-content__block">
        <div className="main-content__block-info">
          <p className="txt">Tell us some basics about your business</p>
          <div className="main-content__block-info-img">
            <img
              src="/images/icons/business_structure/block_2_1of3.svg"
              className="main-content__img"
            />
            <div className="main-content__block-info-img-btns">
              <button className="button" onClick={onScroll("back")}>
                <img src="/images/icons/business_structure/arrow_left.svg" />
              </button>
              <button className="button" onClick={onScroll("next")}>
                <img src="/images/icons/business_structure/arrow_right.svg" />
              </button>
            </div>
          </div>
        </div>
        <div className="main-content__block-form">
          <Formik
            id="business_details"
            enableReinitialize
            initialValues={{ ...initialValues }}
            validationSchema={validationSchema}
            validateOnMount={true}
          >
            {({ handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                name="business_details"
                className="form"
              >
                <MyOnChangeComponent onChange={onChange} />

                <Field
                  title={"Legal business name *"}
                  name="legalBusinessName"
                  component={Input}
                />

                <Field
                  title={"Company registration number *"}
                  name="companyRegistrationNumber"
                  component={Input}
                />

                <Field
                  title={"VAT number"}
                  name="vatNumber"
                  component={Input}
                />

                <Field
                  title={"Doing business as"}
                  name="doingBusinessAs"
                  component={Input}
                />

                <input
                  type="button"
                  value="Next"
                  className="input input--btn"
                  onClick={onScroll("next")}
                  disabled={!_submitEnable}
                />
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default React.memo(BusinessDetailsContainer);

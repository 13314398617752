import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { createContext } from "react";

import Preloader from "../../../Base/Preloader";
import Dropdown from "../../../Base/Filters/Dropdown";
import LinkedAccounts from "./LinkedAccounts";
import Accounts from "./Accounts";
import MoveMoney from "../../../Base/MoveMoney";
import Title from "../../../Base/Title";
import BreadCrumbs from "../../../Base/BreadCrumbs";
import Dashboard from "./Dashboard";
import NavBar from "./NavBar";
import { useContext } from "react";

const AccountContainer = () => {
  const { id } = useParams();
  const [tab, setTab] = useState("Accounts");

  return (
    <AccountContext.Provider
      value={{
        tab,
        setTab,
        tabList: ["Accounts", "Linked accounts", "Auto transfer rules"],
      }}
    >
      <MoveMoney>
        <Account id={id} tab={tab} setTab={setTab} loading={false} />
      </MoveMoney>
    </AccountContext.Provider>
  );
};

const Account = ({ id, loading }) => {
  const { tab } = useContext(AccountContext);

  return loading ? (
    <Preloader />
  ) : (
    <>
      <BreadCrumbs
        links={[
          {
            to: "/profile/account",
            name: "Account",
          },
        ]}
      />

      <Title title="Accounts">
        <Dropdown name={"Move money"} />
      </Title>

      {id ? (
        <Dashboard />
      ) : (
        <div className="main-content">
          <div className="table--main">
            <NavBar />

            {tab === "Linked accounts" && <LinkedAccounts />}
            {tab === "Accounts" && <Accounts />}
          </div>
        </div>
      )}
    </>
  );
};

export const AccountContext = createContext();
export default React.memo(AccountContainer);

import classNames from "classnames";
import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom";

import style from "./style.module.scss";
import Transfer from "./Transfer";

export const MoveMoneyContext = createContext();

const MoveMoneyContainer = ({ children }) => {
  const [active, setActive] = useState(false);

  const closeMoveMoney = useCallback(() => setActive(false), []);
  const openMoveMoney = useCallback(() => setActive(true), []);

  return (
    <MoveMoneyContext.Provider value={{ openMoveMoney, closeMoveMoney }}>
      {children}
      {active && <MoveMoney />}
    </MoveMoneyContext.Provider>
  );
};

const MoveMoney = () => {
  const { closeMoveMoney, openMoveMoney } = useContext(MoveMoneyContext);

  const [active, setActive] = useState(false);
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      setActive(true);
    }

    return () => setActive(false);
  }, [ref]);

  const _closeMoveMoney = () => {
    setActive(false);
    setTimeout(closeMoveMoney, 300);
  };

  return ReactDOM.createPortal(
    <div
      className={classNames(style.container, { [style.active]: active })}
      ref={ref}
    >
      <Transfer
        closeMoveMoney={_closeMoveMoney}
        openMoveMoney={openMoveMoney}
      />
    </div>,
    document.getElementById("mm")
  );
};

export default React.memo(MoveMoneyContainer);

import React from "react";
import classNames from "classnames";
import style from "./style.module.scss";

const CardContainer = ({ children }) => {
  return <div className={style.container}>{children}</div>;
};

const Card = ({ size, title, subtitle, children }) => {
  return (
    <div
      className={classNames(style.card, {
        [style.large]: size === "large",
        [style.medium]: size === "medium",
        [style.small]: size === "small",
        [style.cover]: size === "cover",
      })}
    >
      <h3 className={classNames(style.title, { [style.subtitle]: !!subtitle })}>
        {title}
        {subtitle && subtitle}
      </h3>

      {children}
    </div>
  );
};

export { Card, CardContainer };

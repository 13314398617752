import { useMutation } from "@apollo/client";
import { Field, Formik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { UPDATE_INFO_PROFILE } from "../../../../queries/user/queries";
import { getChangedValues } from "../../../../services/helpers";
import { Input } from "../../../../utils/formcontrol";
import Yup from "../../../../utils/validators";

const ChangePersonalInformationContainer = () => {
  const { initialValues, callbacks } = useSelector((state) => state.modals);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Field is required"),
    lastName: Yup.string().required("Field is required"),
  });

  const [updateProfile, { loading }] = useMutation(UPDATE_INFO_PROFILE, {
    onCompleted: ({
      updateProfile: {
        user: { firstName, lastName },
      },
    }) => {
      const _data = { firstName, lastName };
      callbacks.success(_data);
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  const onSubmit = (data) => {
    const changed = getChangedValues({ ...data }, initialValues);

    if (Object.keys(changed).length) {
      updateProfile({
        variables: {
          input: { ...data },
        },
      });
    } else {
      callbacks.finally();
    }
  };

  return (
    <ChangePersonalInformation
      callbacks={callbacks}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
};

const ChangePersonalInformation = ({
  callbacks,
  initialValues,
  validationSchema,
  onSubmit,
  loading,
}) => {
  return (
    <>
      <Formik
        id="change_personal_information"
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{ ...initialValues }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isValid }) => (
          <form
            onSubmit={handleSubmit}
            name="change_personal_information"
            className="main-content__form"
          >
            <h2 className="form__el-headline">Personal Information</h2>

            <Field
              type="text"
              title={"First Name"}
              name="firstName"
              component={Input}
            />

            <Field
              type="text"
              title={"Last Name"}
              name="lastName"
              component={Input}
            />

            <div className="alert-block__btns">
              <input
                type="button"
                value="Cancel"
                className="input input--btn input--btn--light"
                onClick={callbacks.finally}
              />
              <input
                disabled={!!loading || !isValid}
                type="submit"
                value="Confirm"
                className="input input--btn"
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default React.memo(ChangePersonalInformationContainer);

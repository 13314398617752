import { gql } from "@apollo/client";
import { CORE_SELLER_APPLICATION_FIELDS } from "../business-structure/fragments";
import { CORE_ERROR_FIELDS } from "../user/fragments";

export const CORE_SELLER_APPLICATION_COLLECTION = gql`
  ${CORE_SELLER_APPLICATION_FIELDS}

  fragment CoreSellerApplicationCollection on SellerApplicationCollection {
    collection {
      ...CoreSellerApplication
    }
    metadata {
      currentPage
      limitValue
      totalCount
      totalPages
    }
  }
`;

export const CORE_UPDATE_SELLER_APPLICATION_FIELDS = gql`
  ${CORE_ERROR_FIELDS}
  ${CORE_SELLER_APPLICATION_FIELDS}

  fragment CoreUpdateSellerStatus on UpdateSellerStatus {
    success
    errors {
      ...CoreErrorFields
    }
    sellerApplication {
      ...CoreSellerApplication
    }
  }
`;

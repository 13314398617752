/* eslint-disable no-unused-vars */
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import types from "../../../../redux/types";

const StartProfileContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch({
      type: types.FIRST_SIGN_SUCCESS,
    });

    navigate("/settings/company-profile/business-details");
  };

  return <StartProfile onClick={onClick} />;
};

const StartProfile = ({ onClick }) => {
  return (
    <>
      <div className="registration-header">
        <NavLink to={"/"} className="box-logo"></NavLink>
      </div>

      <div className="box box--small">
        <img src="/images/icons/rocket.svg" className="box-img" />

        <h2 className="box-title box-title--blue">
          <span>Great!</span>
          <span>Let’s get going</span>
        </h2>

        <p className="box-txt">
          To open your AmnaPay account, we'll need to collect some information
          about you and your company.
        </p>

        <input
          type="button"
          value="Start Your Profile"
          className="input input--btn"
          onClick={onClick}
        />

        <p className="box-txt">
          You can fill out this profile in any order and save your progress at
          any point.
        </p>
      </div>
    </>
  );
};

export default React.memo(StartProfileContainer);

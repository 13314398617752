import styled from "styled-components";
import statusLabel from "../utils/statusLabel";
import statusColor from "../utils/statusColor";

export default function StatusOption({ value, onChangeStatus }) {
  return (
    <StatusLabel
      className="link popup-menu__item popup-menu__status"
      onClick={(e) => onChangeStatus(value, e)}
      color={statusColor(value)}
    >
      {statusLabel(value)}
    </StatusLabel>
  );
}

const StatusLabel = styled.div`
  ::before {
    background-color: ${(props) => props.color};
  }
`;

/* eslint-disable no-case-declarations */
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import { encryptor } from "../encryptor";
import types from "../types";

const initialState = {};

const businessDetailsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.UPDATE_BUSINESS_DETAILS:
      const { form, validated = false, ...data } = payload;

      return {
        ...state,
        ...data,
        validated: {
          ...state?.validated,
          [form]: validated,
        },
      };

    default:
      return state;
  }
};

const businessDetailsConfig = {
  key: "amnapay_business_details",
  storage,
  // blacklist: ["validated"],
  transforms: [encryptor],
};

export default persistReducer(businessDetailsConfig, businessDetailsReducer);

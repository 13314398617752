import types from "../types";

const initialState = {
  modal_show: false,
  modal_name: "",
  callbacks: {
    success: () => {},
    error: () => {},
  },
};

const modalsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.SHOW_MODAL:
      return {
        modal_show: true,
        modal_name: payload.name,
        ...payload,
      };

    case types.HIDE_MODAL: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default modalsReducer;

import React from "react";
import { NavLink } from "react-router-dom";
import style from "./style.module.scss";

const BreadCrumbs = ({ links = [] }) => {
  return (
    <div className={style.container}>
      {links.map(({ to, name }, index) => (
        <React.Fragment key={to}>
          <NavLink to={to}>{name}</NavLink>
          {index !== links.length - 1 && "/"}
        </React.Fragment>
      ))}
    </div>
  );
};

export default BreadCrumbs;

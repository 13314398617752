/* eslint-disable no-unused-vars */

import React from "react";
import { NavLink } from "react-router-dom";
import { Card, CardContainer } from "../../../../Base/Cards";
import { Link } from "../../../../Base/Text";
import List from "./List";
import style from "./style.module.scss";

const Dashboard = () => {
  const data = [
    {
      type: "Checking",
      acc_number: "444144559875641",
      balance: 1007,
    },
    {
      type: "Savings",
      acc_number: "444144559875641",
      balance: 3454,
    },
  ];

  return (
    <CardContainer>
      <Card size="small" title="AmnaPay">
        <div className={style.content}>
          <h1 className={style.text}>Cheking ••••4553</h1>

          <div className={style.row}>
            <span>Routing number</span>
            <span>028000121</span>
          </div>

          <div className={style.row}>
            <span>Account number</span>
            <span>
              ••••••4553
              <img src="/images/icons/eye.svg" />
            </span>
          </div>

          <div className={style.row}>
            <span>Bank</span>
            <span>
              Evolve Bank & Trust 6070 Poplar Ave, Suite 200 Memphis, TN 38119
            </span>
          </div>

          <div className={style.nav}>
            <div className={style.action}>
              {" "}
              <img src="/images/icons/dashboard/dollar.svg" />
              Add Funds
            </div>
            <div className={style.action}>
              <img src="/images/icons/dashboard/dollarfile.svg" />
              Wire Details
            </div>
            <div className={style.action}>
              More options
              <img src="/images/icons/dashboard/arrowdown.svg" />
            </div>
          </div>
        </div>
      </Card>

      <Card size="cover" title="Account Balance">
        <div className="graph">
          <h2 className="graph-money">$ 0.00</h2>
          <div className="graph-status graph-status--negative">
            <img
              src="/images/icons/content/arrow-positive.svg"
              className="graph-status__img graph-status__img--positive"
            />
            <img
              src="/images/icons/content/arrow-positive.svg"
              className="graph-status__img graph-status__img--negative"
            />
            <span>$0.00 Today, Dec 23</span>
          </div>
          <div className="graph-body">
            <div className="graph-body__bottom">
              <div>nov 27</div>
              <div>nov 27</div>
              <div>nov 27</div>
              <div>nov 27</div>
              <div>nov 27</div>
              <div>nov 27</div>
            </div>
          </div>
        </div>
      </Card>

      <Card
        size="large"
        title="Recent Transactions"
        subtitle={<Link to="/">View all transactions</Link>}
      >
        <List />
      </Card>
    </CardContainer>
  );
};

export default Dashboard;

/* eslint-disable no-unused-vars */
import { useMutation } from "@apollo/client";
import React, { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  EMAIL_CONFIRMATION,
  UPDATE_USER_CONFIRMATION_TOKEN,
} from "../../../../queries/auth/queries";
import types from "../../../../redux/types";
import Preloader from "../../../Base/Preloader";

const EmailConfirmation = () => {
  const [error, setError] = useState(undefined);
  const { id, confirmationToken } = useParams();
  const dispatch = useDispatch();

  const onCompletedCallback = useCallback(
    ({ emailConfirmation: { success, errors, token, user } }) => {
      if (success) {
        dispatch({
          type: types.SIGN_IN_SUCCESS,
          payload: {
            token: token,
            first_sign: true,
            ...user,
          },
        });
      } else {
        setError(errors[0].message);
      }
    },
    []
  );

  const onErrorCallback = useCallback((error) => {
    setError(error[0].message);
  }, []);

  const [emailConfirmation, { loading: l1 }] = useMutation(EMAIL_CONFIRMATION, {
    onCompleted: onCompletedCallback,
    onError: onErrorCallback,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  const [updateUserConfirmationToken, { loading: l2 }] = useMutation(
    UPDATE_USER_CONFIRMATION_TOKEN,
    {
      onCompleted: ({ updateUserConfirmationToken: { success } }) => {
        if (success) {
          dispatch({
            type: types.SHOW_MODAL,
            payload: {
              name: "CheckYourEmail",
            },
          });
        }
      },
      onError: (err) => console.log(JSON.stringify(err)),
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    setError(undefined);

    emailConfirmation({
      variables: {
        input: {
          confirmationToken,
        },
      },
    });
  }, []);

  return l1 || l2 ? (
    <Preloader />
  ) : error ? (
    <>
      <div className="registration-header">
        <NavLink to={"/"} className="box-logo"></NavLink>
      </div>

      <div className="box box--small text-center">
        <h2 className="box-title box-title--blue">
          <span>Oops!</span>

          <img src="/images/icons/oops.svg" className="box-icon" />
        </h2>

        <p className="box-txt">Unfortunately your Sign-up link has expired!</p>

        <input
          type="button"
          value="Get new link"
          className="input input--btn"
          onClick={() =>
            updateUserConfirmationToken({
              variables: { input: { userId: +id } },
            })
          }
        />
      </div>
    </>
  ) : (
    "Unknow error"
  );
};

export default React.memo(EmailConfirmation);

import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import types from "../../../redux/types";
import ChangesSaved from "./Alerts/ChangesSaved";
import ChangesSavedEmail from "./Alerts/ChangesSavedEmail";
import CheckYourEmail from "./Alerts/CheckYourEmail";
import ConfirmDialog from "./Dialogs/ConfirmDialog";
import PasswordSaved from "./Alerts/PasswordSaved";
import ChangePersonalInformation from "./Dialogs/ChangePersonalInformation";
import ChangePersonalEmail from "./Dialogs/ChangePersonalEmail";
import ChangePersonalPassword from "./Dialogs/ChangePersonalPassword";
import ChangePersonalPhone from "./Dialogs/ChangePersonalPhone";
import ChangeLinkedAccount from "./Dialogs/ChangeLinkedAccount";
import CreateLinkedAccount from "./Dialogs/CreateLinkedAccount";
import TransferStarted from "./Alerts/TransferStarted";

const ModalContainer = () => {
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const { modal_show, modal_name, ...props } = useSelector(
    (state) => state.modals
  );

  const modalClose = () =>
    dispatch({
      type: types.HIDE_MODAL,
    });

  useEffect(() => {
    const clickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        modalClose();
      }
    };

    document.addEventListener("mousedown", clickOutside);

    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [modalRef]);

  return modal_show ? (
    <Modal modalRef={modalRef}>
      {(() => {
        switch (modal_name) {
          case "CreateLinkedAccount":
            return <CreateLinkedAccount />;

          case "ChangeLinkedAccount":
            return <ChangeLinkedAccount />;

          case "ChangesSaved":
            return <ChangesSaved />;

          case "ChangePersonalPhone":
            return <ChangePersonalPhone />;

          case "ChangePersonalPassword":
            return <ChangePersonalPassword />;

          case "ChangesSavedEmail":
            return <ChangesSavedEmail />;

          case "CheckYourEmail":
            return <CheckYourEmail />;

          case "ConfirmDialog":
            return <ConfirmDialog {...props} />;

          case "ChangePersonalEmail":
            return <ChangePersonalEmail />;

          case "ChangePersonalInformation":
            return <ChangePersonalInformation />;

          case "PasswordSaved":
            return <PasswordSaved />;

          case "TransferStarted":
            return <TransferStarted />;
        }
      })()}
    </Modal>
  ) : null;
};

const Modal = ({ children, modalRef }) => {
  return ReactDOM.createPortal(
    <div className="alert">
      <div className="alert-block" ref={modalRef}>
        {children}
      </div>
    </div>,
    document.getElementById("modal")
  );
};

export default React.memo(ModalContainer);

/* eslint-disable no-unused-vars */
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  from,
  HttpLink,
} from "@apollo/client";
import { store } from "../redux/store";
import types from "../redux/types";

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_MAIN_URI}/graphql`,
});

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const { token } = store.getState().auth.data;

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers, status },
    } = context;

    if (status === 401) {
      store.dispatch({
        type: types.SIGN_OUT_SUCCESS,
      });

      return window.location.replace("/auth/signin");
    }

    if (headers) {
      const refreshToken = headers.get("auth-token");

      if (refreshToken) {
        store.dispatch({
          type: types.UPDATE_TOKEN,
          payload: {
            token: refreshToken,
          },
        });
      }
    }

    return response;
  });
});

export const client = new ApolloClient({
  link: from([authLink, afterwareLink, httpLink]), // Chain it with the HttpLink
  cache: new InMemoryCache({
    resultCaching: false,
  }),
});

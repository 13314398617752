import React from "react";
import MotionComponent from "../../MotionComponent";

const Preloader = () => {
  return (
    <MotionComponent>
      <div className="alert">
        <div className="alert-block">
          <div className="lds-default">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <h2 className="alert-block__title">Loading...</h2>
        </div>
      </div>
    </MotionComponent>
  );
};

export default Preloader;

export default function NextStepButton({ onPersonalIdentity, status }) {
  const computedStatus = status?.toLowerCase();
  const value = CurrentStatusButtonText({ status: computedStatus });
  const onClick =
    computedStatus == "approved_for_kyc" ? onPersonalIdentity : () => {};

  if (value) {
    return (
      <input
        onClick={onClick}
        type="button"
        value={value}
        className="input input--btn bricks-btn"
      />
    );
  }

  return null;
}

function CurrentStatusButtonText({ status }) {
  if (status === null) {
    return null;
  }

  switch (status) {
    case "pending":
      return null; // waiting on underwriter to move to approved_for_kyc
    case "approved_for_kyc":
      return "Verify Identity";
    case "rejected_for_kyc":
      return "Request Appeal";
    case "kyc_accepted":
      return null; // nothing for user to do, waiting on underwriter
    case "kyc_declined":
      return "Request Appeal";
    case "approved":
      return "Link Account";
    case "denied":
      return "Request Appeal";
    default:
      return null;
  }
}

import React from "react";

const CheckYourEmail = () => {
  return (
    <>
      <img src="/images/icons/alert_mail.svg" className="alert-block__img" />
      <h2 className="alert-block__title">Check your email</h2>
      We sent an email to make sure you own it. Please, check your inbox and
      follow the instructions
    </>
  );
};

export default CheckYourEmail;

import ReactDOM from "react-dom";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import types from "../../../../../redux/types";
import Title from "../../../../Base/Title";
import BreadCrumbs from "../../../../Base/BreadCrumbs";

const ZoomImage = ({ src, setZoomImage }) => {
  return ReactDOM.createPortal(
    <div className="alert">
      <div className="alert-picture">
        <div
          onClick={() => setZoomImage(false)}
          className="alert-picture__close"
        ></div>
        <img src={src} className="img" />
      </div>
    </div>,
    document.getElementById("alert")
  );
};

const ProductSummaryContainer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const onDelete = useCallback(() => {
    dispatch({
      type: types.SHOW_MODAL,
      payload: {
        name: "ConfirmDialog",
        text: "Are you sure you want to delete this product?",
        callbacks: {
          finally: () => {},
          success: () => {},
        },
      },
    });
  }, []);

  return <ProductSummary id={id} onDelete={onDelete} />;
};

const ProductSummary = ({ id, onDelete }) => {
  const [zoomImage, setZoomImage] = useState(false);

  return (
    <>
      {zoomImage ? (
        <ZoomImage src={"/images/test/car.jpg"} setZoomImage={setZoomImage} />
      ) : null}

      <BreadCrumbs
        links={[
          {
            to: "/profile/products",
            name: "Products",
          },
          {
            to: `/profile/products/${id}`,
            name: "Product",
          },
        ]}
      />

      <Title title="Add new product" />

      <div className="main-content">
        <div className="main-content__title main-content__title--flex">
          <div className="main-content__title-item">
            <p className="txt">Product name</p>
            <a href="#" className="link">
              <img src="/images/icons/pencil.svg" />
            </a>
          </div>

          <div className="main-content__title-item">
            <input
              type="button"
              value={"Status"}
              className="input input--btn input--btn--yellow"
            />
          </div>
        </div>

        <hr className="line" />

        <div className="main-content__colums">
          <div className="main-content__colums-item full">
            <h3 className="title title--flex jc-sb">
              Product details
              <button className="icon--delete" onClick={onDelete}></button>
            </h3>

            <div
              className="image"
              style={{
                backgroundImage: "url(/images/test/car.jpg)",
                cursor: "pointer",
              }}
              onClick={() => setZoomImage(true)}
            />

            <p>Product name</p>

            <p style={{ maxWidth: 390 }}>
              Product's description Lorem ipsum dolor sit amet, consectetur
              adipiscing elit, sed ...consectetur adipiscing elit, se...
            </p>

            <p>1020 USD</p>
          </div>
        </div>

        <hr className="line" />

        <div className="main-content__item">
          <div className="title title--switch">
            <span className="link">History</span>
          </div>
          <div className="main-content__colums">
            <div className="main-content__colums-item">
              <p>
                <span className="txt--blue">John Smith</span>
                <span className="txt--black">
                  made changes - 17/Dec/21 5:15 PM
                </span>
              </p>
              <p>Object</p>
              <p>Address: street, build etc. *</p>
            </div>
            <div className="main-content__colums-item main-content__colums-item--bottom">
              <p>Action</p>
              <p>
                Bobst Library 70 Washington Square South, New York, NY 10012,
                United States
              </p>
            </div>
          </div>

          <hr className="line" />

          <div className="main-content__colums">
            <div className="main-content__colums-item">
              <p>
                <span className="txt--blue">John Smith</span>
                <span className="txt--black">
                  made changes - 17/Dec/21 5:15 PM
                </span>
              </p>
              <p>Object</p>
              <p>Address: street, build etc. *</p>
            </div>
            <div className="main-content__colums-item main-content__colums-item--bottom">
              <p>Action</p>
              <p>
                Bobst Library 70 Washington Square South, New York, NY 10012,
                United States
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ProductSummaryContainer);

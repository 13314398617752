/* eslint-disable no-unused-vars */
import classNames from "classnames";
import React, { useState } from "react";
import CustomSelect from "../CustomSelect";
import style from "../style.module.scss";

const Step2 = ({ prevStep, nextStep, data }) => {
  const [value, setValue] = useState("");
  const [transferFrom, setTransferFrom] = useState("");
  const [transferTo, setTransferTo] = useState("");

  return (
    <>
      <div className={style.card}>
        <div className={style.row}>
          <div className={style.rs_value}>
            $ {(+data?.value || 0).toFixed(2)}
          </div>
        </div>

        <div className={style.row}>
          <div className={classNames(style.field_select, style.rs)}>
            <div className={style.title}>Transfer from</div>

            <CustomSelect
              value={transferFrom}
              setValue={setTransferFrom}
              onlyValue
            />
          </div>
        </div>

        <div className={style.row}>
          <div className={classNames(style.field_select, style.rs)}>
            <div className={style.title}>Transfer to</div>

            <CustomSelect
              value={transferTo}
              setValue={setTransferTo}
              onlyValue
            />
          </div>
        </div>

        <div className={style.row}>
          <div className={classNames(style.calendar, style.rs)}>
            <img src="/images/icons/clndr.svg" />
            External transfers take up to 3 business days.
          </div>
        </div>

        <div className={style.row}>
          <div className={style.footer}>
            <button onClick={prevStep} className={style.back}>
              <img src="/images/icons/toprev.svg" />
              Go back
            </button>
            <button onClick={nextStep}>
              Next <img src="/images/icons/tonext.svg" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step2;

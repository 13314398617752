import React from "react";

const ChangesSaved = () => {
  return (
    <>
      <img src="/images/icons/alert_check.svg" className="alert-block__img" />
      <h2 className="alert-block__title">
        Your changes have been
        <br />
        successfully saved!
      </h2>
    </>
  );
};

export default ChangesSaved;

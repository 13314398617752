import classNames from "classnames";
import statusColor from "../utils/statusColor";
import statusLabel from "../utils/statusLabel";

export default function ApplicationStatusBadge({ status }) {
  const statusClass = (status || "not-created")
    .toLowerCase()
    .replace(/_/gi, "-");

  return (
    <input
      type="button"
      value={statusLabel(status)}
      className={classNames(
        `input input--btn input--btn--status-${statusClass}`
      )}
      style={{ backgroundColor: `${statusColor(status)}` }}
    />
  );
}

/* eslint-disable no-unused-vars */

import * as Yup from "yup";

Yup.addMethod(Yup.string, "zxcvbnType", function (errorMessage) {
  return this.test(`zxcvbn-type`, errorMessage, function (value) {
    const { path, createError } = this;

    return value
      ? !validateNewPassword(value) ||
          createError({ path, message: errorMessage })
      : undefined;
  });
});

Yup.addMethod(Yup.string, "integerType", function () {
  return this.matches(/^\d+$/, "The field should have digits only");
});

const minNumberChecker = (number) => {
  return (value) => {
    return +value.length < number ? value?.length || 0 : undefined;
  };
};

export const upperCase = (value) => {
  return !value.match(/\p{Lu}/gu) ? "uppercase letter" : undefined;
};

export const lowerCase = (value) => {
  return !value.match(/\p{Ll}/gu) ? "lowercase letter" : undefined;
};

export const oneNumber = (value) => {
  return !value.match(/[0-9]/gu) ? "one number" : undefined;
};

const customFieldLevelValidation = (value, validations) => {
  for (let validation of validations) {
    const result = validation(value);

    if (result) return result;
  }

  return undefined;
};

export const validateNewPassword = (value) =>
  customFieldLevelValidation(value, [
    upperCase,
    lowerCase,
    oneNumber,
    minNumberChecker(8),
  ]);

export default Yup;

import React from "react";

const ComingSoon = ({ pageName }) => {
  return (
    <div className="main-content">
      <h3 className="main-content__title">
        {pageName || "Page"} coming soon...
      </h3>
      <hr className="line" />
    </div>
  );
};

export default ComingSoon;

import { useMutation } from "@apollo/client";
import { Field, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  FORGOT_PASSWORD_TOKEN,
  GENERATE_FORGOT_PASSWORD_TOKEN,
} from "../../../../../queries/auth/queries";
import types from "../../../../../redux/types";
import { Input } from "../../../../../utils/formcontrol";
import Yup from "../../../../../utils/validators";

import RollBack from "../../../../Base/RollBack";

const TemporaryContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const { email } = useParams();

  const [forgotPasswordToken, { loading: l1 }] = useMutation(
    FORGOT_PASSWORD_TOKEN,
    {
      onError: (error) => {
        setError(JSON.stringify(error));
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    }
  );

  const [generateForgotPasswordToken, { loading: l2 }] = useMutation(
    GENERATE_FORGOT_PASSWORD_TOKEN,
    {
      onCompleted: ({ generateForgotPasswordToken: { success, errors } }) => {
        if (success) {
          dispatch({
            type: types.SHOW_MODAL,
            payload: {
              name: "CheckYourEmail",
            },
          });
        } else {
          setError(errors?.[0]?.message || "Unknow error");
        }
      },
      onError: (error) => {
        setError(JSON.stringify(error));
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    }
  );

  const onSendAgain = useCallback(() => {
    generateForgotPasswordToken({
      variables: {
        input: {
          email,
        },
      },
    });
  }, []);

  const onSubmit = useCallback(({ password }) => {
    forgotPasswordToken({
      variables: {
        input: {
          restoreToken: password,
        },
      },
      onCompleted: ({ forgotPassword: { success, errors } }) => {
        if (success) {
          navigate(`/auth/forgot-password/new-password/${password}`);
        } else {
          setError(errors?.[0]?.message || "Unknow error");
        }
      },
    });
  }, []);

  const initialValues = {
    password: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Field is required"),
  });

  return (
    <Temporary
      error={error}
      l1={l1}
      l2={l2}
      onSendAgain={onSendAgain}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    />
  );
};

const Temporary = ({
  error,
  l1,
  l2,
  onSubmit,
  onSendAgain,
  initialValues,
  validationSchema,
}) => {
  return (
    <>
      <RollBack />

      <div className="box">
        <NavLink to={"/"} className="box-logo"></NavLink>

        <h2 className="box-title box-title--small">
          Enter your temporary password
        </h2>

        <div className="block-mail">
          <div className="block-mail__pic"></div>
          <h3 className="block-mail__title">Check your email</h3>
          <p>We have sent an email with temporary password</p>
        </div>

        <div className="form-wrapper form-wrapper--indent">
          <Formik
            id="sign_in"
            enableReinitialize
            initialValues={{ ...initialValues }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isValid, touched }) => (
              <form
                onSubmit={handleSubmit}
                name="temporary-password"
                className="form"
              >
                {error && (
                  <div className="message-errror">
                    <div className="message-errror__pic"></div>
                    <p>{error}</p>
                  </div>
                )}

                <Field
                  type="password"
                  title={"Code *"}
                  name="password"
                  component={Input}
                  autoComplete="new-password"
                />

                <input
                  type="submit"
                  value="Confirm"
                  className="input input--btn"
                  disabled={!(isValid && Object.keys(touched).length) || l1}
                />

                <div className="form__el-message form__el-message--rightside">
                  <a
                    className="link txt--blue"
                    disabled={l2}
                    onClick={onSendAgain}
                  >
                    Send again
                  </a>
                </div>
              </form>
            )}
          </Formik>
        </div>

        <div className="box-bottom">
          <div className="form-wrapper">
            <hr className="line" />
            <p className="box-bottom__txt">
              If you have any problem{" "}
              <NavLink to={"/"} className="link txt--blue">
                Contact Us
              </NavLink>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(TemporaryContainer);

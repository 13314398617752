import React from "react";

export function SplitView({ children }) {
  const [left, right] = children;
  return (
    <div
      style={{
        display: `flex`,
        flexDirection: `row`,
        gap: `1.5rem`,
      }}
    >
      <div style={{ borderRight: `1px solid #d6d6d6` }}>{left}</div>

      {right}
    </div>
  );
}

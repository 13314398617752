export default function CurrentStatusDescription({ status }) {
  switch (status?.toLowerCase()) {
    case "pending":
      return "Thanks for submitting your application. Waiting on underwriter review.";
    case "approved_for_kyc":
      return "Ready for Identify Verification. Tell us a bit about yourself.";
    case "rejected_for_kyc":
      return "Your seller application has been denied.";
    case "kyc_accepted":
      return "Your identify has been verified, waiting on underwriter approval.";
    case "kyc_declined":
      return "Your identify verification has failed.";
    case "approved":
      return "Your AmnaPay account is ready for your use. Let's get it funded.";
    case "denied":
      return "Your seller application has been denied.";
    default:
      return null;
  }
}

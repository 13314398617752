import { gql } from "@apollo/client";
import {
  CORE_KYC_VERIFICATION,
  CORE_UPDATE_EMAIL_FIELDS,
  CORE_UPDATE_PASSWORD_FIELDS,
  CORE_UPDATE_USER_FIELDS,
  CORE_USER_FIELDS,
} from "./fragments";

// QUERIES

export const GET_PROFILE = gql`
  ${CORE_USER_FIELDS}
  query profile {
    profile {
      ...CoreUser
    }
  }
`;

export const GET_KYC_LINK = gql`
  ${CORE_KYC_VERIFICATION}

  query startKycVerification {
    startKycVerification {
      ...CoreKycVerification
    }
  }
`;

// MUTATIONS

export const UPDATE_INFO_PROFILE = gql`
  ${CORE_UPDATE_USER_FIELDS}

  mutation updateProfile($input: UpdateProfileMutationInput!) {
    updateProfile(input: $input) {
      ...CoreUpdateUserProfile
    }
  }
`;

export const UPDATE_EMAIL_PROFILE = gql`
  ${CORE_UPDATE_EMAIL_FIELDS}

  mutation updateEmail($input: UpdateUserEmailMutationInput!) {
    updateEmail(input: $input) {
      ...CoreUpdateUserEmail
    }
  }
`;

export const UPDATE_PASSWORD_PROFILE = gql`
  ${CORE_UPDATE_PASSWORD_FIELDS}

  mutation updatePassword($input: UpdateUserPasswordMutationInput!) {
    updatePassword(input: $input) {
      ...CoreUpdateUserPassword
    }
  }
`;

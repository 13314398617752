import { put, takeLatest, call, all } from "redux-saga/effects";
import api from "../../services/api";
import types from "../types";

function* postSignIn({ payload }) {
  try {
    const { data, status } = yield call(api.auth.signin, payload.data);
    if (status < 200 || status >= 300) throw new Error("Something went wrong");
    yield put({ type: types.SIGN_IN_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: types.SIGN_IN_FAILURE });
    const _error = error.response.data?.errors
      ? error.response.data.errors[
          Object.keys(error.response.data.errors)[0]
        ][0]
      : error.response.data.error || "Internal Server Error";

    payload.callbackError(_error);

    if (error?.response?.status === 401) return;
  }
}

function* postSignUp({ payload }) {
  try {
    const { data, status } = yield call(api.auth.signup, payload.data);
    if (status < 200 || status >= 300) throw new Error("Something went wrong");
    yield put({ type: types.SIGN_UP_SUCCESS, payload: data });
    payload.callbackSuccess();
  } catch (error) {
    yield put({ type: types.SIGN_UP_FAILURE });

    // 422 Unprocessable Entity - Validation Errors
    if (error?.response?.status === 422) {
      payload.onValidationError(error?.response?.data?.errors);
    } else {
      const _error = error.response.data?.errors
        ? error.response.data.errors[
            Object.keys(error.response.data.errors)[0]
          ][0]
        : error.response.data.error || "Internal Server Error";
      payload.callbackError(_error);
    }

    if (error?.response?.status === 401) return;
  }
}

function* deleteSignOut({ payload }) {
  try {
    const { status } = yield call(api.auth.signout);
    if (status < 200 || status >= 300) throw new Error("Something went wrong");
    yield put({ type: types.SIGN_OUT_SUCCESS });
    setTimeout(() => {
      payload.callbackSuccess();
    }, 0);
  } catch (error) {
    yield put({ type: types.SIGN_OUT_FAILURE });
    if (error?.response?.status === 401) return;
  }
}

export function* rootSagaAuth() {
  yield all([
    takeLatest(types.SIGN_IN_START, postSignIn),
    takeLatest(types.SIGN_UP_START, postSignUp),
    takeLatest(types.SIGN_OUT_START, deleteSignOut),
  ]);
}

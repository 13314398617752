import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const HeaderContainer = () => {
  const { token } = useSelector((state) => state.auth.data);

  return <Header token={token} />;
};

const Header = ({ token }) => {
  return (
    <header className="header">
      <div className="wrapper">
        <div className="header-box">
          <NavLink
            to={token ? "/profile" : "/"}
            className="header-box__logo"
          ></NavLink>
        </div>
      </div>
    </header>
  );
};

export default React.memo(HeaderContainer);

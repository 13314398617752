import classNames from "classnames";
import { Field, Formik, useFormikContext } from "formik";
import React, { useEffect, useRef } from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { scroller } from "react-scroll";
import { Input, Select } from "../../../../utils/s_formcontrol";
import Yup from "../../../../utils/validators";

const BankDetailsContainer = ({
  sellerApplication,
  onChange,
  onSubmit,
  submitEnable,
  fields,
}) => {
  const { validated } = useSelector((state) => state.businessDetails);

  const _submitEnable = useMemo(
    () => validated?.personal && validated?.business_details,
    [validated?.personal, validated?.business_details]
  );

  const initialValues = {
    bankId: sellerApplication?.bankId || "",
    routingNumber: sellerApplication?.routingNumber || "",
    accountNumber: sellerApplication?.accountNumber || "",
    accountNumberConfirmation:
      sellerApplication?.accountNumberConfirmation || "",
  };

  const validationSchema = Yup.object().shape({
    bankId: Yup.string().required("Field is required"),
    routingNumber: Yup.string()
      .min(3)
      .max(20)
      .integerType()
      .required("Field is required"),
    accountNumber: Yup.string()
      .min(3)
      .max(20)
      .integerType()
      .required("Field is required"),
    accountNumberConfirmation: Yup.string()
      .integerType()
      .required("Field is required")
      .test(
        "account-number-match",
        "Account number must match",
        function (value) {
          return this.parent.accountNumber === value;
        }
      ),
  });

  return (
    <BankDetails
      initialValues={initialValues}
      validationSchema={validationSchema}
      onChange={onChange}
      onSubmit={onSubmit}
      _submitEnable={_submitEnable}
      submitEnable={submitEnable}
      fields={fields}
    />
  );
};

const MyOnChangeComponent = ({ onChange }) => {
  const didMount = useRef(false);
  const { values, isValid } = useFormikContext();

  useEffect(() => {
    if (didMount.current)
      onChange({
        ...values,
        validated: isValid,
        form: "bank_details",
      });
    else didMount.current = true;
  }, [values, isValid]);

  return null;
};

const BankDetails = ({
  initialValues,
  validationSchema,
  onChange,
  onSubmit,
  _submitEnable,
  submitEnable,
  fields,
}) => {
  const onScroll = () => {
    scroller.scrollTo(
      document.getElementById("business_details_sc")
        ? "business_details_sc"
        : "personal_information_sc",
      {
        duration: 1000,
        smooth: "easeInOutQuart",
        containerId: "scroller",
        offset: -35,
      }
    );
  };

  return (
    <div
      className={classNames(
        "main-content__container main-content__container--third",
        { "main-content__container__active": _submitEnable }
      )}
      id="bank_details_sc"
    >
      <h2 className="main-content__title">Bank details</h2>

      <div className="main-content__block">
        <div className="main-content__block-info">
          <p className="txt">
            A payout is the transfer of funds from stripe to your bank account.
            Link your account to seamlessly receive payouts and help us better
            understand your business.
          </p>
          <div className="main-content__block-info-img">
            <img
              src="/images/icons/business_structure/block_3_1of3.svg"
              className="main-content__img"
            />
            <img
              src="/images/icons/business_structure/block_3_1of2.svg"
              className="main-content__img main-content__img--sole-proprietorship"
            />
            <div className="main-content__block-info-img-btns">
              <button className="button" onClick={onScroll}>
                <img src="/images/icons/business_structure/arrow_left.svg" />
              </button>
              <button className="button" disabled>
                <img src="/images/icons/business_structure/arrow_right.svg" />
              </button>
            </div>
          </div>
        </div>

        <div className="main-content__block-form">
          <Formik
            id="bank_details"
            enableReinitialize
            initialValues={{ ...initialValues }}
            validationSchema={validationSchema}
            validateOnMount={true}
          >
            {({ handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                name="bank_details"
                className="form"
              >
                <MyOnChangeComponent onChange={onChange} />

                <Field
                  title={"Select an account for payouts *"}
                  name="bankId"
                  component={Select}
                  options={fields?.banks?.map((b) => {
                    return {
                      name: b.name,
                      value: +b.id,
                    };
                  })}
                />

                <Field
                  title={"Routing number *"}
                  name="routingNumber"
                  component={Input}
                />

                <Field
                  title={"Account number *"}
                  name="accountNumber"
                  component={Input}
                />

                <Field
                  title={"Repeat account number *"}
                  name="accountNumberConfirmation"
                  component={Input}
                />

                <input
                  disabled={!submitEnable}
                  onClick={onSubmit}
                  type="button"
                  value="Confirm"
                  className="input input--btn"
                />
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default React.memo(BankDetailsContainer);

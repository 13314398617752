/* eslint-disable no-unused-vars */
import { useMutation } from "@apollo/client";
import { Field, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { CREATE_COMMENT } from "../../../../queries/business-structure/queries";
import { Textarea } from "../../../../utils/s_formcontrol";
import Yup from "../../../../utils/validators";

const CommentsContainer = ({ underwriter, comments, id }) => {
  const initialValues = {
    text: "",
  };

  const validationSchema = Yup.object().shape({
    text: Yup.string().required(),
  });

  const [errror, setError] = useState(null);
  const [_comments, setComments] = useState(comments || []);

  const [createComment, { loading }] = useMutation(CREATE_COMMENT, {
    onCompleted: ({ createComment: { success, comment, errors } }) => {
      if (success) {
        setComments([..._comments, comment]);
      } else {
        setError(errors?.[0]?.message || "Unknow error");
      }
    },
    onError: (error) => {
      console.log(JSON.stringify(error));
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  const onSubmit = useCallback(
    ({ text }) => {
      createComment({
        variables: {
          input: {
            sellerApplicationId: id,
            text,
          },
        },
      });
    },
    [id]
  );

  return (
    <Comments
      underwriter={underwriter}
      comments={_comments}
      onSubmit={onSubmit}
      loading={loading}
      initialValues={initialValues}
      validationSchema={validationSchema}
    />
  );
};

const Comments = ({
  underwriter,
  comments,
  onSubmit,
  loading,
  initialValues,
  validationSchema,
}) => {
  return (
    <>
      {comments?.length ? (
        <div className="main-content__colums comments">
          {comments.map((h, i) => {
            return (
              <React.Fragment key={i}>
                <div className="main-content__colums-item full">
                  <p>
                    <span className="txt--blue">{`${h?.user?.firstName} ${h?.user?.lastName}`}</span>
                    <span className="txt--black">
                      commented - {new Date(h.createdAt).toLocaleDateString()} -{" "}
                      {new Date(h.createdAt).toLocaleTimeString()}
                    </span>
                  </p>
                </div>
                <div className="main-content__colums-item full">
                  <p>{h.text}</p>
                </div>
                <hr className="line" />
              </React.Fragment>
            );
          })}
        </div>
      ) : null}

      {underwriter && (
        <Formik
          id="create_comment"
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{ ...initialValues }}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isValid, values }) => (
            <form
              onSubmit={handleSubmit}
              name="change_personal_information"
              className="main-content__form comment"
              id="comments_sc"
            >
              <Field
                type="text"
                name="text"
                component={Textarea}
                className="comment"
              />

              <input
                disabled={!!loading || !isValid || !values.text.length}
                type="submit"
                value="Confirm"
                className="input input--btn small"
              />
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

export default React.memo(CommentsContainer);

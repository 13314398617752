/* eslint-disable no-unused-vars */
import ReactDOM from "react-dom";
import React, { useCallback, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import types from "../../../../redux/types";
import CustomSelect from "../../../Base/Filters/CustomSelect";
import CustomMultiRangeSlider from "../../../Base/Filters/CustomMultiRangeSlider";
import Title from "../../../Base/Title";
import BreadCrumbs from "../../../Base/BreadCrumbs";

const ZoomImage = ({ src, setZoomImage }) => {
  return ReactDOM.createPortal(
    <div className="alert">
      <div className="alert-picture">
        <div
          onClick={() => setZoomImage(false)}
          className="alert-picture__close"
        ></div>
        <img src={src} className="img" />
      </div>
    </div>,
    document.getElementById("alert")
  );
};

const Row = () => {
  const navigate = useNavigate();
  const [zoomImage, setZoomImage] = useState(false);

  const dispatch = useDispatch();

  const onDelete = useCallback((e) => {
    e.stopPropagation();
    dispatch({
      type: types.SHOW_MODAL,
      payload: {
        name: "ConfirmDialog",
        text: "Are you sure you want to delete this product?",
        callbacks: {
          finally: () => {},
          success: () => {},
        },
      },
    });
  }, []);

  const showImage = useCallback((e) => {
    e.stopPropagation();
    setZoomImage(true);
  }, []);

  return (
    <>
      {zoomImage ? (
        <ZoomImage src={"/images/test/car.jpg"} setZoomImage={setZoomImage} />
      ) : null}

      <tr onClick={() => navigate("25")}>
        <td>
          <div className="main-table__main-name-container">
            <div href="#" className="link main-table__main-name-img">
              <img
                src="/images/test/car.jpg"
                className="img"
                onClick={showImage}
              />
            </div>
            <span className="txt">John Smith</span>
          </div>
        </td>
        <td>
          <div className="main-table__main-name-description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
            ...consectetur adipiscing elit, se...
          </div>
        </td>
        <td>1 999 2876,99</td>
        <td>$ USD</td>
        <td>-</td>
        <td>New Yourk</td>
        <td className="main-table__main-status disabled">
          <span className="table-status table-status--new">new</span>
        </td>
        <td>
          <button className="icon--delete" onClick={onDelete}></button>
        </td>
      </tr>
    </>
  );
};

const ProductsContainer = () => {
  const [countRows, setCountRows] = useState(10);
  const [page, setPage] = useState(1);

  const [statuses, setStatuses] = useState([
    { value: "new", label: "New", status: true },
    { value: "on_review", label: "On review", status: true },
    { value: "need_info", label: "Need info", status: true },
    { value: "rejected", label: "Rejected", status: true },
    { value: "approved", label: "Approved", status: true },
  ]);

  const [currencies, setCurrencies] = useState([
    { value: "usd", label: "USD", status: true },
    { value: "eur", label: "EUR", status: true },
    { value: "uah", label: "UAH", status: true },
  ]);

  const data = [];

  return (
    <Products
      data={data}
      countRows={countRows}
      page={page}
      setPage={setPage}
      statuses={statuses}
      setStatuses={setStatuses}
      currencies={currencies}
      setCurrencies={setCurrencies}
    />
  );
};

const Products = ({
  data,
  countRows,
  page,
  setPage,
  statuses,
  setStatuses,
  currencies,
  setCurrencies,
}) => {
  return (
    <>
      <BreadCrumbs
        links={[
          {
            to: "/profile/products",
            name: "Products",
          },
        ]}
      />

      <Title title="Products">
        <NavLink to="add" className="input input--btn input--btn--plus">
          Add Product
        </NavLink>
      </Title>

      <div className="main-header">
        <div className="main-header__filters">
          <CustomSelect
            name={"Status"}
            value={statuses}
            setValue={setStatuses}
          />
          <CustomSelect
            name={"Currency"}
            value={currencies}
            setValue={setCurrencies}
          />

          <CustomMultiRangeSlider min={0} max={100} onChange={() => {}} />
        </div>

        <div className="main-header__searcher">
          {/* <form className="searcher">
            <input
              type="text"
              className="input input--searcher"
              placeholder="Search exact name, company etc..."
            />

            <button className="searcher-loupe">
              <img src="/images/icons/loupe.svg" />
            </button>
          </form> */}
        </div>
      </div>

      <div className="main-content main-table">
        <div className="table--main">
          <table className="table products">
            <thead>
              <tr>
                <th>Name</th>
                <th>DESCRIPTION</th>
                <th>cost</th>
                <th>Currency</th>
                <th>Discount</th>
                <th>City</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <Row />
            </tbody>
          </table>
        </div>
      </div>

      <div className="main-footer">
        <div className="main-footer__number">
          <p className="txt">View</p>
          <input
            type="text"
            className="input input--number"
            value={countRows}
            disabled
          />
          <p className="txt">records per page</p>
        </div>
        <div className="main-footer__pages">
          {/* <p className="txt">Showing 1 to 50 of 2,210 entries</p> */}

          <ReactPaginate
            nextLabel="Next"
            initialPage={page - 1}
            onPageChange={(e) => {
              setPage(e.selected + 1);
            }}
            pageRangeDisplayed={5}
            pageCount={data?.metadata?.totalPages || 1}
            previousLabel="Prev"
            renderOnZeroPageCount={null}
            containerClassName={"main-footer__pages-controller page-controller"}
            pageClassName={"page-controller__num"}
            pageLinkClassName={"link"}
            activeClassName={"page-controller__num--active"}
            previousClassName={
              "page-controller__num page-controller__num--prev"
            }
            nextClassName={"page-controller__num page-controller__num--next"}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(ProductsContainer);

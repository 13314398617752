import { Field, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import types from "../../../../redux/types";
import {
  Input,
  InputCheckBox,
  InputNewPassword,
} from "../../../../utils/formcontrol";
import Yup from "../../../../utils/validators";
import Preloader from "../../../Base/Preloader";

const SignUpContainer = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const { loader } = useSelector((state) => state.auth);

  const onSubmit = useCallback((data, { setErrors, resetForm }) => {
    setError(false);

    dispatch({
      type: types.SIGN_UP_START,
      payload: {
        callbackSuccess: () => {
          resetForm();
          dispatch({
            type: types.SHOW_MODAL,
            payload: {
              name: "CheckYourEmail",
            },
          });
        },
        callbackError: (err) => setError(err),
        onValidationError: (err) => setErrors(err),
        data: {
          ...data,
          terms_of_service: data.terms_of_service ? "yes" : "no",
          privacy_policy: data.terms_of_service ? "yes" : "no",
        },
      },
    });
  }, []);

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    terms_of_service: false,
    privacy_policy: true,
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("Field is required"),
    last_name: Yup.string().required("Field is required"),
    email: Yup.string().required("Field is required").email(),
    password: Yup.string().required("Field is required").zxcvbnType(),
    password_confirmation: Yup.string().test(
      "passwords-match",
      "Passwords must match",
      function (value) {
        return this.parent.password === value;
      }
    ),
    terms_of_service: Yup.bool().required("Field is required").oneOf([true]),
    privacy_policy: Yup.bool().required("Field is required").oneOf([true]),
  });

  return (
    <SignUp
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      error={error}
      loader={loader}
    />
  );
};

const SignUp = ({
  onSubmit,
  initialValues,
  validationSchema,
  error,
  loader,
}) => (
  <>
    {loader && <Preloader />}

    <div className="box">
      <NavLink to={"/"} className="box-logo"></NavLink>

      <h2 className="box-title">Welcome to AmnaPay!</h2>

      {error && (
        <div className="message-errror">
          <div className="message-errror__pic"></div>
          {error === "Something wrong. Please try again or" ? (
            <p>
              Something wrong. Please try again or{" "}
              <NavLink to={"/"} className="link txt--blue">
                get a new one
              </NavLink>
            </p>
          ) : (
            <p>{error}</p>
          )}
        </div>
      )}

      <div className="form-wrapper">
        <Formik
          id="sign_up"
          enableReinitialize
          initialValues={{ ...initialValues }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isValid, touched }) => (
            <form onSubmit={handleSubmit} name="sign_up" className="form">
              <Field
                type="text"
                title={"First Name *"}
                name="first_name"
                component={Input}
              />

              <Field
                type="text"
                title={"Last Name *"}
                name="last_name"
                component={Input}
              />

              <Field
                type="text"
                title={"Email *"}
                name="email"
                component={Input}
                autoComplete="new-password"
              />

              <Field
                type="password"
                title={"Password *"}
                name="password"
                component={InputNewPassword}
                autoComplete="new-password"
              />

              <Field
                type="password"
                title={"Repeat Password *"}
                name="password_confirmation"
                component={Input}
                autoComplete="new-password"
              />

              <Field
                type="checkbox"
                name="terms_of_service"
                component={InputCheckBox}
              >
                <>
                  I agree to the terms of{" "}
                  <a
                    href={require("../../../Base/Pdf/amnapay_tos_2.pdf")}
                    className="link txt--blue"
                    target="_blank"
                    rel="noreferrer"
                  >
                    service
                  </a>{" "}
                  and{" "}
                  <a
                    href={require("../../../Base/Pdf/amnapay_privacy_policy.pdf")}
                    className="link txt--blue"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy policy
                  </a>
                </>
              </Field>

              <input
                type="submit"
                value="Sign Up"
                className="input input--btn"
                disabled={!(isValid && Object.keys(touched).length) || loader}
              />

              <div className="form__el-message form__el-message--leftside">
                Already have an account?
                <NavLink to={"/auth/signin"} className="link txt--blue">
                  Sign In
                </NavLink>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  </>
);

export default React.memo(SignUpContainer);

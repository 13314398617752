import React from "react";

const History = ({ histories }) => {
  return (
    <div className="main-content__colums">
      {histories.map((h, i) => {
        return (
          <React.Fragment key={i}>
            <div className="main-content__colums-item full">
              <p>
                <span className="txt--blue">{h.id}</span>
                <span className="txt--black">
                  made changes - {new Date(h.createdAt).toLocaleDateString()} -{" "}
                  {new Date(h.createdAt).toLocaleTimeString()}
                </span>
              </p>
            </div>
            <div className="main-content__colums-item">
              <p>Object</p>
              <p>{h.field}</p>
            </div>
            <div className="main-content__colums-item main-content__colums-item--bottom">
              <p>Action</p>
              <p>
                {h.oldValue}
                <br />
                {h.value}
              </p>
            </div>
            <hr className="line" />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default React.memo(History);

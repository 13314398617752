import { useEffect } from "react";
import { GET_SELLER_BUSINESSES } from "../queries/sellers/queries";

import { useLazyQuery } from "@apollo/client";

export default function WithBusinesses({ children }) {
  const [getSellerBusinesses, { loading: loading, data: data }] = useLazyQuery(
    GET_SELLER_BUSINESSES
  );

  useEffect(() => {
    getSellerBusinesses();
  }, [getSellerBusinesses]);

  if (loading && !data) {
    return "pending...";
  }

  return children({ businesses: data?.businesses });
}

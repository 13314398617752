import React, { useCallback, useContext } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import types from "../../../../../redux/types";
import {
  cutAccountNumber,
  cutCardNumber,
} from "../../../../../services/helpers";
import { MoveMoneyContext } from "../../../../Base/MoveMoney";
import ElipsisMenu from "../../../../Base/Table/ElipsisMenu";

const LinkedAccounts = () => {
  const dispatch = useDispatch();
  const { openMoveMoney } = useContext(MoveMoneyContext);

  const modalClose = () =>
    dispatch({
      type: types.HIDE_MODAL,
    });

  const onCreateNewAccount = useCallback(() => {
    dispatch({
      type: types.SHOW_MODAL,
      payload: {
        name: "CreateLinkedAccount",
        callbacks: {
          success: () => {
            modalClose();
          },
          error: () => {},
          finally: () => {
            modalClose();
          },
        },
      },
    });
  }, []);

  const onCopy = (text) => {
    navigator.clipboard.writeText(text);
    NotificationManager.success("Successfully copied");
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Account number</th>
          <th>Routing number</th>
          <th colSpan={2}></th>
        </tr>
      </thead>

      <tbody className="pe-none">
        {/* <tr>
                  <td colSpan={5}>No data to show</td>
                </tr> */}

        <tr>
          <td>
            <div className="main-table__main-name-container">
              <div className="main-table__main-name-img bg-transparent">
                <img src="/images/icons/table/bank.svg" className="img" />
              </div>
              <div className="txt--acc">
                BUS COMPLETE CHK {cutCardNumber(444144559875641)}
                <br />
                <span>Checking - Chase</span>
              </div>
            </div>
          </td>

          <td>
            <div className="main-table__with_icon">
              {cutAccountNumber(444144559875641)}
              <img
                className="main-table__icon"
                src="/images/icons/eye.svg"
                alt="exchange"
              />
            </div>
          </td>

          <td>
            <div
              className="main-table__routing"
              data-tooltip="Click to copy"
              onClick={() => onCopy("028000121")}
            >
              028000121
            </div>
          </td>
          <td>
            <div
              className="main-table__with_icon exchange"
              onClick={openMoveMoney}
            >
              <div
                className="main-table__icon exchange"
                src="/images/icons/exchange.svg"
                alt="exchange"
              />
              Transfer Money
            </div>
          </td>
          <td>
            <ElipsisMenu />
          </td>
        </tr>

        <tr className="main-table__border-row">
          <td>
            <div
              className="main-table__main-name-container link"
              onClick={() => onCreateNewAccount()}
            >
              <div className="main-table__main-name-img bg-transparent">
                <img src="/images/icons/table/plus.svg" className="img" />
              </div>

              <div className="txt--acc">Link Account</div>
            </div>
          </td>

          <td colSpan={4}></td>
        </tr>
      </tbody>
    </table>
  );
};

export default LinkedAccounts;

import { gql } from "@apollo/client";
import {
  CORE_ERROR_FIELDS,
  CORE_PHONE_NUMBER,
  CORE_USER_FIELDS,
} from "../user/fragments";

export const CORE_BANK_FIELDS = gql`
  fragment CoreBank on Bank {
    id
    name
  }
`;

export const CORE_SUBDIVISION_FIELDS = gql`
  fragment CoreSubdivision on Subdivision {
    name
  }
`;

export const CORE_COUNTRY_FIELDS = gql`
  ${CORE_SUBDIVISION_FIELDS}
  fragment CoreCountry on Country {
    code
    name
    subdivisions {
      ...CoreSubdivision
    }
  }
`;

export const CORE_PLATFORM_FIELDS = gql`
  fragment CorePlatform on Platform {
    id
    name
  }
`;

export const CORE_REVENUES_FIELDS = gql`
  fragment CoreRevenue on Revenue {
    id
    name
  }
`;

export const CORE_HISTORIES_FIELDS = gql`
  fragment CoreHistories on SellerApplicationLog {
    createdAt
    field
    id
    oldValue
    updatedAt
    value
  }
`;

export const CORE_COMMENTS_FIELDS = gql`
  ${CORE_USER_FIELDS}

  fragment CoreComment on Comment {
    createdAt
    id
    sellerApplicationId
    text
    updatedAt
    user {
      ...CoreUser
    }
  }
`;

export const CORE_SELLER_APPLICATION_FIELDS = gql`
  ${CORE_PLATFORM_FIELDS}
  ${CORE_REVENUES_FIELDS}
  ${CORE_USER_FIELDS}
  ${CORE_PHONE_NUMBER}
  ${CORE_HISTORIES_FIELDS}
  ${CORE_COMMENTS_FIELDS}

  fragment CoreSellerApplication on SellerApplication {
    accountNumber
    address
    bankId
    comments {
      ...CoreComment
    }
    companyRegistrationNumber
    country
    createdAt
    doingBusinessAs
    histories {
      ...CoreHistories
    }
    id
    identificationNumber
    legalBusinessName
    phone {
      ...CorePhone
    }
    platform {
      ...CorePlatform
    }
    region
    revenue {
      ...CoreRevenue
    }
    routingNumber
    status
    typeOfBusiness
    underwriter {
      ...CoreUser
    }
    updatedAt
    user {
      ...CoreUser
    }
    vatNumber
    website
  }
`;

export const CORE_CREATE_SELLER_APPLICATION_FIELDS = gql`
  ${CORE_ERROR_FIELDS}
  ${CORE_SELLER_APPLICATION_FIELDS}

  fragment CoreCreateSellerApplication on CreateSellerApplication {
    success
    errors {
      ...CoreErrorFields
    }
    sellerApplication {
      ...CoreSellerApplication
    }
  }
`;

export const CORE_UPDATE_SELLER_APPLICATION_FIELDS = gql`
  ${CORE_ERROR_FIELDS}
  ${CORE_SELLER_APPLICATION_FIELDS}

  fragment CoreUpdateSellerApplication on UpdateSellerApplication {
    success
    errors {
      ...CoreErrorFields
    }
    sellerApplication {
      ...CoreSellerApplication
    }
  }
`;

export const CORE_SEND_VERIFICATION_SMS_FIELDS = gql`
  ${CORE_ERROR_FIELDS}

  fragment CoreSendVerificationSms on PhoneVerification {
    success
    errors {
      ...CoreErrorFields
    }
  }
`;

export const CORE_VERIFY_PHONE = gql`
  ${CORE_ERROR_FIELDS}

  fragment CoreVerifyPhone on PhoneVerification {
    success
    errors {
      ...CoreErrorFields
    }
  }
`;

export const CORE_CREATE_COMMENT = gql`
  ${CORE_ERROR_FIELDS}
  ${CORE_COMMENTS_FIELDS}

  fragment CoreCreateComment on CreateComment {
    comment {
      ...CoreComment
    }
    success
    errors {
      ...CoreErrorFields
    }
  }
`;

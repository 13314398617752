/* eslint-disable no-unused-vars */
import { Field, Formik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Input } from "../../../../utils/formcontrol";
import Yup from "../../../../utils/validators";

const ChangeLinkedAccountContainer = () => {
  const { callbacks } = useSelector((state) => state.modals);
  const [error, setError] = useState(undefined);

  const initialValues = {
    accountName: "",
  };

  const validationSchema = Yup.object().shape({
    accountName: Yup.string().required("Field is required"),
  });

  const onSubmit = () => {
    callbacks.success();
  };

  return (
    <ChangeLinkedAccount
      callbacks={callbacks}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      loading={false}
      error={error}
    />
  );
};

const ChangeLinkedAccount = ({
  callbacks,
  initialValues,
  validationSchema,
  onSubmit,
  loading,
  error,
}) => {
  return (
    <>
      <Formik
        id="change_linked_accounts"
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{ ...initialValues }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isValid, dirty }) => (
          <form
            onSubmit={handleSubmit}
            name="change_linked_accounts"
            className="main-content__form"
          >
            <h2 className="form__el-headline">Edit account name</h2>

            {error && (
              <div className="message-errror">
                <div className="message-errror__pic"></div>
                <p>{error}</p>
              </div>
            )}

            <Field
              type="text"
              title={"Account name"}
              name="accountName"
              component={Input}
            />

            <div className="alert-block__btns">
              <input
                type="button"
                value="Cancel"
                className="input input--btn input--btn--light"
                onClick={callbacks.finally}
              />
              <input
                disabled={loading || !(isValid && dirty)}
                type="submit"
                value="Confirm"
                className="input input--btn"
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default React.memo(ChangeLinkedAccountContainer);

import React, { useContext } from "react";
import { cutCardNumber } from "../../../../../services/helpers";
import { MoveMoneyContext } from "../../../../Base/MoveMoney";

const List = () => {
  const { openMoveMoney } = useContext(MoveMoneyContext);

  return (
    <div className="table--main">
      <table className="table">
        <tbody className="pe-none">
          {/* <tr>
                  <td colSpan={5}>No data to show</td>
                </tr> */}

          <tr>
            <td>21/12/22</td>
            <td>
              <div className="main-table__main-name-container">
                <div className="main-table__main-name-img bg-transparent">
                  <img src="/images/icons/table/bank.svg" className="img" />
                </div>
                <div className="txt--acc">
                  Chase-cheking {cutCardNumber(444144559875641)}
                </div>
              </div>
            </td>

            <td>
              <div
                className="main-table__with_icon exchange"
                onClick={openMoveMoney}
              >
                <div
                  className="main-table__icon exchange"
                  src="/images/icons/exchange.svg"
                  alt="exchange"
                />
                Transfer Money
              </div>
            </td>

            <td>$ 1 007.00</td>
          </tr>

          <tr>
            <td>21/12/22</td>
            <td>
              <div className="main-table__main-name-container">
                <div className="main-table__main-name-img bg-transparent">
                  <img src="/images/icons/table/bank.svg" className="img" />
                </div>
                <div className="txt--acc">
                  Chase-cheking {cutCardNumber(444144559875641)}
                </div>
              </div>
            </td>

            <td>
              <div
                className="main-table__with_icon exchange"
                onClick={openMoveMoney}
              >
                <div
                  className="main-table__icon exchange"
                  src="/images/icons/exchange.svg"
                  alt="exchange"
                />
                Transfer Money
              </div>
            </td>

            <td>$ 1 007.00</td>
          </tr>

          <tr>
            <td>21/12/22</td>
            <td>
              <div className="main-table__main-name-container">
                <div className="main-table__main-name-img bg-transparent">
                  <img src="/images/icons/table/bank.svg" className="img" />
                </div>
                <div className="txt--acc">
                  Chase-cheking {cutCardNumber(444144559875641)}
                </div>
              </div>
            </td>

            <td>
              <div
                className="main-table__with_icon exchange"
                onClick={openMoveMoney}
              >
                <div
                  className="main-table__icon exchange"
                  src="/images/icons/exchange.svg"
                  alt="exchange"
                />
                Transfer Money
              </div>
            </td>

            <td>$ 1 007.00</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default List;

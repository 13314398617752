import { motion } from "framer-motion";

export default function MotionComponent(props) {
  const baseVariant = {
    initial: {
      opacity: 0,
    },

    in: {
      opacity: 1,
    },

    out: {
      opacity: 0,
    },
  };

  return (
    <motion.div
      transition={{
        y: { type: "spring", stiffness: 500, damping: 200 },
      }}
      initial="initial"
      animate="in"
      exit="out"
      variants={baseVariant}
      className={`page${props?.className ? ` ${props.className}` : ""}`}
    >
      {props.children}
    </motion.div>
  );
}

import { gql } from "@apollo/client";
import {
  CORE_BANK_FIELDS,
  CORE_COUNTRY_FIELDS,
  CORE_CREATE_SELLER_APPLICATION_FIELDS,
  CORE_PLATFORM_FIELDS,
  CORE_REVENUES_FIELDS,
  CORE_UPDATE_SELLER_APPLICATION_FIELDS,
  CORE_SEND_VERIFICATION_SMS_FIELDS,
  CORE_VERIFY_PHONE,
  CORE_CREATE_COMMENT,
} from "./fragments";

// QUERIES

export const GET_BUSINESS_STRUCTURE = gql`
  ${CORE_BANK_FIELDS}
  ${CORE_COUNTRY_FIELDS}
  ${CORE_PLATFORM_FIELDS}
  ${CORE_REVENUES_FIELDS}

  query countries {
    countries {
      ...CoreCountry
    }

    platforms {
      ...CorePlatform
    }

    revenues {
      ...CoreRevenue
    }

    banks {
      ...CoreBank
    }
  }
`;

// MUTATIONS createSellerApplication

export const CREATE_SELLER_APPLICATION = gql`
  ${CORE_CREATE_SELLER_APPLICATION_FIELDS}

  mutation createSellerApplication(
    $input: CreateSellerApplicationMutationInput!
  ) {
    createSellerApplication(input: $input) {
      ...CoreCreateSellerApplication
    }
  }
`;

export const UPDATE_SELLER_APPLICATION = gql`
  ${CORE_UPDATE_SELLER_APPLICATION_FIELDS}

  mutation updateSellerApplication(
    $input: UpdateSellerApplicationMutationInput!
  ) {
    updateSellerApplication(input: $input) {
      ...CoreUpdateSellerApplication
    }
  }
`;

export const SEND_VERIFICATION_SMS = gql`
  ${CORE_SEND_VERIFICATION_SMS_FIELDS}

  mutation sendVerificationSms($input: SendVerificationSmsMutationInput!) {
    sendVerificationSms(input: $input) {
      ...CoreSendVerificationSms
    }
  }
`;

export const VERIFY_PHONE = gql`
  ${CORE_VERIFY_PHONE}

  mutation verifyPhone($input: VerifyPhoneMutationInput!) {
    verifyPhone(input: $input) {
      ...CoreVerifyPhone
    }
  }
`;

export const CREATE_COMMENT = gql`
  ${CORE_CREATE_COMMENT}

  mutation createComment($input: CreateCommentMutationInput!) {
    createComment(input: $input) {
      ...CoreCreateComment
    }
  }
`;

import React from "react";
import classNames from "classnames";
import style from "./style.module.scss";

const LeftSideBar = ({ step = 1 }) => {
  const links = ["Transfer details", "Review"];

  return (
    <div className={style.sidebar}>
      <ul className={style.list}>
        {links.map((l, i) => (
          <li className={classNames({ [style.active]: step > i })} key={l}>
            {l}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LeftSideBar;

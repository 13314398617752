import "./styles/css/style.scss";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import { ApolloProvider } from "@apollo/client";
import { client } from "./apolloClient/apolloClient";
import App from "./components/App/index";
import Preloader from "./components/Base/Preloader";

export const Index = () => {
  return <App />;
};

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <PersistGate loading={<Preloader />} persistor={persistor}>
        <BrowserRouter>
          <Index />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);

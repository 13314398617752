import classNames from "classnames";
import React, { useEffect, useRef } from "react";

const FilterHOC = ({
  children,
  className,
  setStatus,
  status,
  disabled = false,
}) => {
  const selectRef = useRef(null);

  useEffect(() => {
    const clickOutside = (e) => {
      if (selectRef.current && !selectRef.current.contains(e.target)) {
        setStatus(false);
      }
    };

    document.addEventListener("mousedown", clickOutside);

    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [selectRef]);

  return (
    <div
      className={classNames(
        `main-header__filters-item popup ${className || ""}`,
        {
          "popup--active": status,
        },
        {
          disabled: disabled,
        }
      )}
      ref={selectRef}
    >
      {children}
    </div>
  );
};

export default FilterHOC;

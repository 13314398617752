import { gql } from "@apollo/client";
import { CORE_SELLER_REQUEST_COUNTER_FIELDS } from "./fragments";

export const GET_SELLER_REQUEST_COUNTER = gql`
  ${CORE_SELLER_REQUEST_COUNTER_FIELDS}

  query sellerRequestCounter {
    sellerRequestCounter {
      ...CoreSellerRequestCounter
    }
  }
`;

import { gql } from "@apollo/client";

export const GET_VIRTUAL_CARDS = gql`
  query virtualCards {
    virtualCards {
      id
      name
      businessId
      cardName
      cardNumber
    }
  }
`;

export const GET_VIRTUAL_CARD = gql`
  query virtualCard($virtualCardId: ID!) {
    virtualCard(virtualCardId: $virtualCardId) {
      id
      businessId
      name
      cardName
      cardNumber
      cvv
      expirationDate
      dollarLimit
    }
  }
`;

export const CREATE_VIRTUAL_CARD = gql`
  mutation createVirtualCard($input: CreateVirtualCardMutationInput!) {
    createVirtualCard(input: $input) {
      virtualCard {
        id
        cardName
        cardNumber
        cvv
        expirationDate
      }
      success
    }
  }
`;

export const UPDATE_VIRTUAL_CARD = gql`
  mutation updateVirtualCard($input: UpdateVirtualCardMutationInput!) {
    updateVirtualCard(input: $input) {
      success

      virtualCard {
        cardName
        cardNumber
      }

      errors {
        message
        path
      }
    }
  }
`;

export default function statusColor(value) {
  const statuses = {
    pending: "var(--blue)",
    approved_for_kyc: "var(--orange)",
    kyc_accepted: "var(--yellow)",
    approved: "var(--green)",
    rejected_for_kyc: "var(--red)",
    kyc_declined: "var(--red)",
    declined: "var(--red)",
  };

  return statuses[value?.toLowerCase()];
}

/* eslint-disable no-unused-vars */
import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { GET_SELLER_REQUEST_COUNTER } from "../../../../queries/dashboard/queries";

const HomeUnderwriterContainer = ({ firstName }) => {
  const [counterState, setCounterState] = useState({
    sellers: 0,
    products: 0,
  });

  useQuery(GET_SELLER_REQUEST_COUNTER, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onCompleted: ({ sellerRequestCounter: { sellerRequestCount } }) => {
      setCounterState({ ...counterState, sellers: sellerRequestCount });
    },
  });

  return <HomeUnderwriter firstName={firstName} counterState={counterState} />;
};

const HomeUnderwriter = ({ firstName, counterState }) => {
  return (
    <div className="main-content main-content--bricks">
      <div className="main-content--bricks--big underwriter">
        <h2 className="bricks-content-title">
          Welcome,
          <span>{firstName}!</span>
          <img
            src="/images/icons/good-bye.svg"
            className="bricks-content-title__img"
          />
        </h2>
        <div className="bricks-content-txt">
          Let's verify some sellers today!
        </div>
      </div>

      <div className="main-content--bricks--medium">
        <h3 className="bricks-title">
          New sellers' requests for profile verification
        </h3>
        <div className="bricks-content">
          <div className="bricks-content__status">You received</div>
          <h2 className="bricks-content__money">{counterState.sellers}</h2>

          <div className="bricks-content__links">
            <NavLink
              to="/profile/sellers_applications"
              className="link txt--blue bricks-content__links-item"
            >
              View All
            </NavLink>
          </div>
        </div>
      </div>

      <div className="main-content--bricks--medium">
        <h3 className="bricks-title">New product requests for verification</h3>
        <div className="bricks-content">
          <div className="bricks-content__status">You received</div>

          <h2 className="bricks-content__money">{counterState.products}</h2>

          <div className="bricks-content__links">
            <NavLink
              to="/profile/products"
              className="link txt--blue bricks-content__links-item"
            >
              View All
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(HomeUnderwriterContainer);

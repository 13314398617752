/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import types from "../../../../redux/types";
import Preloader from "../../../Base/Preloader";

const SignOut = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State to track if an error occurs
  const [error, setError] = useState(null);

  useEffect(() => {
    try {
      // Dispatch action
      dispatch({
        type: types.SIGN_OUT_START,
      });

      dispatch({
        type: types.SIGN_OUT_SUCCESS,
      });

      // Navigate after dispatch
      navigate("/");
    } catch (error) {
      // Handle any errors here
      setError("Error:", error);
    }
  }, [dispatch, navigate]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Preloader />
    </>
  );
};

export default React.memo(SignOut);

import React, { useCallback, useEffect, useState, useRef } from "react";
import FilterHOC from "./FilterHOC";
import "./multiRangeSlider.scss";

const CustomMultiRangeSlider = ({ min, max, onChange }) => {
  const [status, setStatus] = useState(false);

  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent, status]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent, status]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({ min: minVal, max: maxVal });
  }, [minVal, maxVal, onChange]);

  return (
    <FilterHOC className={"range"} status={status} setStatus={setStatus}>
      <button
        className="input input--btn input--btn--light input--cost"
        onClick={() => setStatus(!status)}
      >
        Cost
      </button>

      {status && (
        <div className="popup-menu popup-menu--range">
          <div className="popup-menu__bottom-control">
            <input
              type="text"
              value={minVal}
              className="input input--currency"
              disabled
            />
            <img src="/images/icons/table/arrow-input.svg" className="img" />
            <input
              value={maxVal}
              type="text"
              className="input input--currency"
              disabled
            />
          </div>

          <div className="slider">
            <input
              type="range"
              min={min}
              max={max}
              value={minVal}
              onChange={(event) => {
                const value = Math.min(Number(event.target.value), maxVal - 1);
                setMinVal(value);
                minValRef.current = value;
              }}
              className="thumb thumb--left"
              style={{ zIndex: minVal > max - 100 && "5" }}
            />
            <input
              type="range"
              min={min}
              max={max}
              value={maxVal}
              onChange={(event) => {
                const value = Math.max(Number(event.target.value), minVal + 1);
                setMaxVal(value);
                maxValRef.current = value;
              }}
              className="thumb thumb--right"
            />

            <div className="slider__track" />
            <div ref={range} className="slider__range" />
            <div className="slider__left-value">{minVal}</div>
            <div className="slider__right-value">{maxVal}</div>
          </div>
        </div>
      )}
    </FilterHOC>
  );
};

export default React.memo(CustomMultiRangeSlider);

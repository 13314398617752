/* eslint-disable no-unused-vars */
import React from "react";
import classNames from "classnames";

import style from "./style.module.scss";
import { useMemo } from "react";
import { cutAccountNumber } from "../../../../../services/helpers";
import { useNavigate } from "react-router-dom";

const Accounts = () => {
  const navigate = useNavigate();

  const data = [
    {
      type: "Checking",
      acc_number: "444144559875641",
      balance: 1007,
    },
    {
      type: "Savings",
      acc_number: "444144559875641",
      balance: 3454,
    },
  ];

  const _balance = useMemo(() => {
    return data.reduce((acc, val) => acc + val.balance, 0);
  }, [data]);

  const convertValue = (val) =>
    val.toFixed(2).replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + " ");

  return (
    <>
      <div className={style.balance}>
        <div className={style.title}>AmnaPay Balance</div>
        <div className={style.value}>$ {convertValue(_balance)}</div>
      </div>

      <table className={classNames("table", style.table)}>
        <thead>
          <tr>
            <th>Account Number</th>
            <th>Balance</th>
          </tr>
        </thead>

        <tbody>
          {data.map(({ type, acc_number, balance }) => (
            <tr key={acc_number} onClick={() => navigate(acc_number)}>
              <td>
                <div className={style.chase}>
                  <img src="/images/icons/chase.svg" />
                  {type} {cutAccountNumber(acc_number)}
                </div>
              </td>
              <td>$ {convertValue(balance)}</td>
            </tr>
          ))}

          {/* <td colSpan={2}>No data to show</td> */}
        </tbody>
      </table>
    </>
  );
};

export default Accounts;

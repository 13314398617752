import React from "react";
import { Link } from "react-router-dom";

export function VirtualCardsIndex({ virtualCards }) {
  if (virtualCards.length) {
    return (
      <div>
        <h1>Virtual Cards</h1>
        Choose a Card to the left or <Link to="new">Add a Card</Link>
      </div>
    );
  }

  return (
    <div>
      <h1>No cards present</h1>
      <p>
        <Link to="new">Add a Card</Link>
      </p>
    </div>
  );
}

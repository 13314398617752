/* eslint-disable no-unused-vars */
import classNames from "classnames";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_PROFILE } from "../../queries/user/queries";
import routes from "../../routes/routes";

import AuthenticatedApp from "../AuthenticatedApp";

import Modal from "../Base/Modals";
import { useDispatch } from "react-redux";
import types from "../../redux/types";
import Preloader from "../Base/Preloader";
import { onDynamicTitle } from "../../services/helpers";
import { NotificationContainer } from "react-notifications";
import { GlobalStyleVariables } from "../GlobalStyleVariables";

import "react-notifications/lib/notifications.css";

const AppContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { pathname } = location;
  const { token, first_sign, embedded } = useSelector(
    (state) => state.auth.data
  );
  const { publicRoutes, privateRoutes, otherRoutes } = routes;

  const routing = useRoutes(
    token ? (first_sign ? otherRoutes() : privateRoutes()) : publicRoutes()
  );

  const { loading } = useQuery(GET_PROFILE, {
    skip: !token,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      dispatch({
        type: types.LOAD_USER_SUCCESS,
        payload: {
          ...data.profile,
        },
      });
    },
    onError: (error) => {
      dispatch({
        type: types.LOAD_USER_FAILURE,
      });
      console.log(error);
    },
  });

  useEffect(async () => {
    if (token) {
      if (first_sign) {
        navigate("/auth/start-profile");
      }
    }
  }, [token, navigate]);

  useEffect(() => {
    onDynamicTitle(pathname);
  }, [pathname]);

  return (
    <App
      routing={routing}
      token={token}
      loading={false} // loading
      first_sign={first_sign}
      embedded={embedded}
    />
  );
};

const App = ({ routing, token, loading, first_sign, embedded }) => {
  return (
    <>
      <GlobalStyleVariables />

      {loading ? (
        <Preloader />
      ) : token && !first_sign ? (
        <AuthenticatedApp routing={routing} embedded={embedded} />
      ) : (
        <div className={classNames("wrapper", "wrapper wrapper--registration")}>
          {routing}
        </div>
      )}
      <Modal />
      <NotificationContainer />
    </>
  );
};

export default React.memo(AppContainer);
